import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {RenameData, RenameEditedProject} from "../../../../services/event-bus.service";
import {NewProject} from "../../../../interfaces/app.interfaces";

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent implements OnInit {

  @ViewChild('input') input!: ElementRef;

  id!: string;
  newName!:string;
  oldName!:string;
  data!: NewProject;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig<NewProject>) { }

  ngOnInit(): void {
    this.focusInput();
    if(this.config.data) {
      this.data = this.config.data;
      this.newName = this.data.title;
      this.oldName = this.data.title;
    }
  }

  focusInput() {
    setTimeout(() => {
      this.input.nativeElement.focus();
    })
  }

  isRenameDisabled(): boolean{
    return (this.newName === this.oldName) || (this.newName.trim().length === 0)
  }

  doRename(): void{
    const  renameData : RenameEditedProject = {
      project: this.data,
      newName: this.newName.trim()
    }

    this.ref.close(renameData);
  }

  doCancel() {
    this.ref.close(null)
  }

}
