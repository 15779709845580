import {Component, ElementRef, OnInit, signal, ViewChild} from '@angular/core';
import {MenuItem} from "primeng/api";
import {ApiService} from "../../../services/api.service";
import {MsalService} from "@azure/msal-angular";
import {DialogService} from "primeng/dynamicdialog";
import {HelpContentComponent} from "../dialogs/help/help-content.component";
import {EventBusService} from "../../../services/event-bus.service";
import {Avatar} from "primeng/avatar";
import {AboutContentComponent} from "../dialogs/about/about-content.component";
import {BrowserStorageService} from "../../../services/browser-storage.service";
import packageJson from "../../../../../package.json";
import {SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DialogService]
})
export class HeaderComponent implements OnInit {

  logoPath = './assets/images/chat-ix.png';
  items: MenuItem[] = [];

  @ViewChild(Avatar, {read: ElementRef}) avatar!: ElementRef;

  userInitials = this.apiService.userInitials;
  profilePictureUrl = this.apiService.profilePictureUrl;

  readonly STORAGE_VERSION_KEY = 'chatix.seenVersion';
  version = packageJson.version;

  constructor(
      public apiService: ApiService,
      public msal: MsalService,
      public dialogService: DialogService,
      private eventBus: EventBusService,
      private browserStorage: BrowserStorageService,
  ) { }

  private handleFirstOpen(): void {
    const value = this.browserStorage.retrieveValue(this.STORAGE_VERSION_KEY);
    if (this.version !== value) {
      this.aboutModal()
    }
  }

  private getAboutDialogHeader(): string {
    const value = this.browserStorage.retrieveValue(this.STORAGE_VERSION_KEY);
    if (this.version !== value) {
      return `Welcome to Chat-iX`
    }
    return `Chat-iX Version ${this.version} by N-iX OTPS`;
  }

  private aboutModal(): void {
    const ref = this.dialogService.open(AboutContentComponent, {
      header: this.getAboutDialogHeader(),
      contentStyle: {
        "overflow": "auto",
        "width": "864px",
      },
    });
    ref.onClose.subscribe(() => {
      this.browserStorage.saveValue(this.STORAGE_VERSION_KEY, this.version)
    });
  }

  private helpModal(): void {
    const ref = this.dialogService.open(HelpContentComponent, {
      header: 'Help',
      contentStyle: {
        "overflow": "auto",
        "width": "800px",
          "height": "60vh"
      },
    });
    ref.onClose.subscribe(() => {

    });
  }

  setMenuItems(): void {
    this.items = [{
      label: this.apiService.contextData.email,
      items: [
        {
          label: 'About Chat-iX',
          icon: 'pi pi-info-circle',
          command: () => {
            this.aboutModal();
          }
        },
        {
          label: 'Help',
          icon: 'pi pi-question-circle',
          command: () => {
            this.helpModal();
          }
        },
        {
          label: 'Sign out',
          icon: 'pi pi-sign-out',
          command: () => {
            this.signOut();
          }
        },
      ]
    },
    ];
  }

  signOut(): void {
    this.msal.logout();
  }

  ngOnInit(): void {
    this.handleFirstOpen();
    this.setMenuItems();

    this.eventBus.openAbout.subscribe((value) => {
      this.aboutModal();
    });
  }
}
