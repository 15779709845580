import { Injectable } from '@angular/core';
import {NewFavorite, NewProject} from "../interfaces/app.interfaces";
import {StateModel} from "./models/state.model";

@Injectable({
  providedIn: 'root'
})
export class SidebarSelectionService extends StateModel<SidebarSelectionState> {

  constructor() {
    super()
  }

  selectProject(project: SidebarSelection, newProject: boolean = false) {
    this.set({
      project,
      newProject
    })
  }
}


export interface SidebarSelectionState {
  project: SidebarSelection;
  newProject: boolean;
}

export interface SidebarSelection extends NewProject, NewFavorite {

}


