import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IImage, IImageCollection} from "../../../../../sidebar/sidebar.component";
import {ImageCollectionService} from "../../../../../../../services/image-collection.service";
import {AppStateService} from "../../../../../../../services/app.state.service";
import {catchError} from "rxjs/operators";
import {finalize, tap, throwError} from "rxjs";
import {SpinnerPosition} from "../../../../../../../shared/spinner/spinner.component";
import {EventBusService, SpinnerLocation} from "../../../../../../../services/event-bus.service";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {ConfirmationService, MenuItem} from "primeng/api";
import {NewProject} from "../../../../../../../interfaces/app.interfaces";
import {Model} from "../../../../../../../services/api.service";

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  providers: [ConfirmationService]
})
export class ImageComponent implements OnInit {

  @Input() image!: IImage;
  @Input() imageCollection!: NewProject;
  @Input() isFirst!: boolean;

  @Output() onCreate: EventEmitter<CreateData> = new EventEmitter<CreateData>();
  @Output() onDelete: EventEmitter<DeleteData> = new EventEmitter<DeleteData>();

  previewShown = false;
  imageUrl!: string;
  failed =  false;
  isCopying = false;
  isLoading = false;
  cachedImage?: IImage;
  imageModel?: Model;
  protected SpinnerPosition = SpinnerPosition;

  menuItems: MenuItem[] = [
    {
      icon: 'pi pi-copy',
      label: 'Copy prompt',
      command: () => {
        this.copyPrompt();
      }
    },
    {
      icon: 'pi pi-code',
      label: 'Use prompt',
      command: () => {
        this.usePrompt();
      }
    },
    {
      icon: 'pi pi-replay',
      label: 'Regenerate',
      command: () => {
        this.regenerateImage();
      }
    },
    {
      icon: 'pi pi-download',
      label: 'Download',
      command: () => {
        this.downloadImage();
      }
    },
    {
      icon: 'pi pi-trash',
      label: 'Delete',
      command: () => {
        this.confirmDeleteImage();
      }
    },
  ];

  constructor(
      private imageService: ImageCollectionService,
      private stateService: AppStateService,
      private eventBus: EventBusService,
      private confirmationService: ConfirmationService,
  ) {

  }

  generateUrl(imageUrl: string):string {
    return imageUrl;
  }

  getBackgroundClass() {
    return `background-image: url(${this.generateUrl(this.image.thumbnailUrl)})`;
  }

  ngOnInit(): void {

    this.cachedImage = {...this.image};

    if(this.image.existing) {
      this.imageModel = this.image.model;
    } else {
      this.submitImageCreation(this.image.prompt);
    }

  }


  getImageRendition() {
    this.imageService.getImageRendition(this.imageCollection.id, this.image.id).pipe(
        catchError((error) => {
          this.failed = true;
          return throwError(() => new Error('Unable to get image. ' +  error.toString()))
        })
    ).subscribe((imageBlob) => {
      if (imageBlob) {
              const reader = new FileReader();
              reader.onload = () => {
                this.imageUrl = reader.result as string;
              };
              reader.readAsDataURL(imageBlob);
      }

    })
  }

  submitImageCreation(message: string):void {
    this.isLoading = true;
    this.imageService.submitImageCreation(this.imageCollection.id, message, this.stateService.selectedAIModel.value).pipe(
        catchError((error)=> {
          return throwError(() => {
            //TODO: remove failed property and handle deletion in array
            this.failed = true;
            return new Error('Failed to generate image');
          })
        }),
        finalize(() => {
          this.isLoading = false;
        })
    ).subscribe(images => {
      const newImage = {...images[0], existing: true};
      this.onCreate.emit({
        image: newImage,
        tempId: this.image.tempId
      });
      this.image = newImage;
      this.imageModel = newImage.model;

      if(this.isFirst) {
        this.eventBus.updateImageCollectionNameById.send(this.imageCollection.id);
      }

     //  this.getImageRendition();
    },() => {
      this.onDelete.emit({
        image: this.image
      });
    })
  }

  copyPrompt() {
    navigator.clipboard.writeText(this.image.prompt).then();
    this.isCopying = true;
    setTimeout(()=> {
      this.isCopying = false;
    }, 2000);
  }

  downloadImage() {
    this.doDownload(this.image.downloadUrl, `${new Date().getTime()}.png`)
  }

  usePrompt() {
    this.eventBus.newPrompt.next(this.image.prompt);
  }

  confirmDeleteImage(): void {
    this.confirmationService.confirm({
      key: 'image-item',
      message: 'Are you sure you want to delete this image?',
      accept: () => {
        this.deleteImage();
      }
    });
  }

  deleteImage() {
    this.imageService.deleteImage(this.imageCollection.id, this.image.id).pipe(
        tap(() => {
          this.eventBus.setIsLoading(true, SpinnerLocation.Body)
        }),
        finalize(()=> {
          this.eventBus.setIsLoading(false, SpinnerLocation.Body)
        })
    ).subscribe(() => {
      this.onDelete.emit({
        image: this.image
      });

    })
  }

  regenerateImage():void {
    const imageCache = {...this.image};
    this.isLoading = true;
    this.imageService.regenerateImage(this.imageCollection.id, imageCache.id).pipe(
        catchError(() => {
          this.image.id = imageCache.id;

          return throwError(( ) => new Error('Image generation failed'))
        }),
        finalize(() => {
          this.isLoading = false;
        })
    ).subscribe(image => {
      this.onCreate.emit({
        image: image,
        tempId: this.image.tempId
      });
      this.image = {...image};
    })
  }

  private doDownload(imageUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.target = 'blank';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export interface CreateData {
  image: IImage;
  tempId: string;
}
export interface DeleteData {
  image: IImage
}


