import {Injectable, model, signal, WritableSignal} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {Conversation} from "../main/components/sidebar/sidebar.component";
import {AppStateService} from "./app.state.service";
import {NewFavorite, NewProject, NewProjectTypeName} from "../interfaces/app.interfaces";
import {map} from "rxjs/operators";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {SafeUrl} from "@angular/platform-browser";
import {group} from "@angular/animations";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly endpoint: string = environment.backend.url;
  private _contextData!: ContextData;
  private _userInitials: WritableSignal<string | undefined> = signal(undefined);
  private _profilePictureUrl: WritableSignal<SafeUrl | undefined> = signal(undefined);

  public NotSelectedModel: NotSelectedModel = new NotSelectedModel();

  constructor(
      private http: HttpClient,
      private state: AppStateService
  ) { }

  // TODO Consider moving this contextData staff to the state service
  get contextData(): ContextData {
    return this._contextData;
  }

  set contextData(value: ContextData) {
    this._contextData = value;
  }

  get userInitials(): WritableSignal<string | undefined> {
    return this._userInitials;
  }

  get profilePictureUrl(): WritableSignal<SafeUrl | undefined> {
    return this._profilePictureUrl;
  }

  // TODO: Refactor this quick fix
  getDefaultChatModel(): Model {
    return this._contextData.availableModels.filter(group => group.displayName === 'Chat')[0].models[0];
  }

  // TODO: Refactor this quick fix
  getDefaultImageModel(): Model {
    return this._contextData.availableModels.filter(group => group.displayName === 'Images')[0].models[0];
  }

  getModelByName(modelName: String): Model {
    return this._contextData.availableModels.flatMap(g => g.models).filter(m => m.name === modelName)[0];
  }

  getProjects(): Observable<NewProject[]> {
    return this.http.get<NewProject[]>(`${this.endpoint}/projects`);
  }

  getFavorites(): Observable<NewFavorite[]> {
    return this.http.get<NewFavorite[]>(`${this.endpoint}/favorites`).pipe(
        map(projects => {
          return projects.map(project => {
            return {...project,  tempGuid: uuidv4()}
          })
        })
    );
  }

  deleteConversation(id: string): Observable<Conversation> {
    return this.http.delete<Conversation>(`${this.endpoint}/conversations/${id}`);
  }

  renameConversation(id: string, title: string): Observable<NewProject> {
    const data = {
      title
    }
    return this.http.patch<NewProject>(`${this.endpoint}/conversations/${id}`,data);
  }

  getConversationById(id: string): Observable<Conversation> {
    return this.http.get<Conversation>(`${this.endpoint}/conversations/${id}`);
  };

  getConversationProjectById(id: string): Observable<NewProject> {
    return this.http.get<NewProject>(`${this.endpoint}/conversations/${id}`);
  };

  updateFavoriteStatus(projectId: string, status: boolean): Observable<NewProject> {
    const payload = {
      isFavorite: status
    }
    return this.http.patch<NewProject>(`${this.endpoint}/projects/${projectId}`, payload);
  };

  createConversation(model: Model): Observable<NewProject> {
    const payload = {
      model: model.name
    }
    return this.http.post<NewProject>(`${this.endpoint}/streaming/conversations`,payload).pipe(
        // TODO: remove this map, when models on backend will be in sync
        map(data => {
          return {...data, projectTypeName: NewProjectTypeName.Conversation}
        } )
    );
  };

  changeConversationModel(conversationId: string, model: Model): Observable<NewProject> {
    const payload = {
      value: model.name
    }
    return this.http.put<NewProject>(`${this.endpoint}/conversations/${conversationId}/model`, payload).pipe(
        // TODO: remove this map, when models on backend will be in sync
        map(data => {
          return {...data, projectTypeName: NewProjectTypeName.Conversation}
        }));
  }

  changeImageCollectionModel(imageCollectionId: string, model: Model): Observable<NewProject> {
    const payload = {
      value: model.name
    }
    return this.http.put<NewProject>(`${this.endpoint}/image-collections/${imageCollectionId}/model`, payload).pipe(
        // TODO: remove this mapping if/when image-collection returns projectTypeName
        map(data => {
          return {...data, projectTypeName: NewProjectTypeName.ImageCollection}
        }));
  }

  getContext(): Observable<ContextData> {
    const params = new HttpParams({

    })
    return this.http.get<ContextData>(`${this.endpoint}/current-user/context`);
  }

}

export interface ContextData {
  displayName: string;
  email: string;
  firstName: string;
  lastName: string;
  availableModels: ModelGroup[];
}

export interface ModelGroup {
  id: string;
  displayName: string;
  models: Model[];
}

export interface Model {
  id: string;
  name: string;
  displayName: string;
  outputType: string;
  vendor: Vendor;
}

export interface Vendor {
  id: string;
  name: string;
  displayName: string;
  outputType: string;
}

export class NotSelectedModel implements Model {
  displayName: string = "Not Selected"
  id: string = "abb7d0aa-beae-498a-b16c-4852e9adae7b";
  name: string = "not-selected";
  outputType: string = "none";
  vendor: Vendor = { displayName: "", id: "", name: "", outputType: "" };
}