import {SidebarSelection} from "../services/sidebar-selection.service";
import {Model} from "../services/api.service";

export interface NewProject {
    id: string;
    model: Model;
    title: string;
    creationDate: Date;
    modificationDate: Date;
    isFavorite: boolean;
    isLocked: boolean;
    projectTypeName: NewProjectTypeName
}

export interface NewFavorite extends NewProject {
    tempGuid?: string;
}
export enum NewProjectTypeName {
    Conversation = 'Conversation',
    ImageCollection = 'ImageCollection',
    NotSelected = 'NotSelected'
}

export interface ToggleFavoriteEvent {
    project: SidebarSelection,
    status: boolean;
}