<div class="about-wrapper">
    <p-accordion [multiple]="true">
        <p-accordionTab header="About Chat-iX" [selected]="true" class="about">
            <div [className]="activeFeature ? 'feature-inner active' : 'feature-inner'">
                <div class="feature-wrapper">
                    <div style="padding: 15px 15px 0 15px; overflow: hidden;">
                        <div class="title">Introducing <span class="corporate-color">Chat-iX</span>, an app that allows you to effortlessly interact with multiple AI models through a user-friendly interface. Exclusively designed for the N-iX team, it prioritizes the security of sensitive information. Currently, it's powered by OpenAI GPT models, DALL·E version 2 and 3, Anthropic Claude 3 models - Haiku, Sonnet and Opus, Google Gemini 1.5 Pro and PaLM 2, with other models joining the party soon.
                            <p>
                                Chat-iX originated as a research project by the OTPS team, aimed at discovering practical use cases for cognitive AI technologies in everyday business routines. It rapidly gained traction and received high praise from the N-iX community, driving our efforts to enhance functionality and user experience.
                            </p>
                            <p>
                                Whether you're looking to streamline your workflow, get creative with AI-generated content, or explore the limitless possibilities of the AI multiverse, Chat-iX is here to help you do it confidently.
                            </p>
                        </div>
                        <ng-container *ngFor="let version of feature;">
                            <div class="item">
                                <div class="item-title">
                                    {{version.title}}
                                    <span class="pl-2 date-info">{{version.date | date:'MMMM dd, yyyy'}}</span>
                                </div>
                                <ng-container *ngFor="let item of version.features;">
                                    <div class="item-text"><span>{{item.text}}</span></div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <span *ngIf="!activeFeature" class="ellipsis">. . .</span>
                <div class="show-more" (click)="toggleMoreFeature()">
                    <ng-container *ngIf="activeFeature">Show less</ng-container>
                    <ng-container *ngIf="!activeFeature">Show more</ng-container>
                </div>
            </div>
        </p-accordionTab>
        <p-accordionTab header="Coming soon" [selected]="true" class="soon">
            <div [className]="activeComingSoon ? 'feature-inner soon active' : 'feature-inner soon'">
                <div class="feature-wrapper">
                    <div style="padding: 15px 15px 0 15px; overflow: hidden;">
                        <div class="item">
                            <ng-container *ngFor="let item of comingSoon.features;">
                                <div class="item-text"><span>{{item.text}}</span></div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <span *ngIf="!activeComingSoon" class="ellipsis">. . .</span>
                <div class="show-more" (click)="toggleMoreComingSoon()">
                    <ng-container *ngIf="activeComingSoon">Show less</ng-container>
                    <ng-container *ngIf="!activeComingSoon">Show more</ng-container>
                </div>
            </div>
        </p-accordionTab>
    </p-accordion>
    
</div>
