import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {MessageService} from "primeng/api";
import {EventBusService} from "../services/event-bus.service";

@Injectable()
export class ErrorNotifierInterceptor implements HttpInterceptor {

  constructor(
      private messageService: MessageService,
      private eventBus: EventBusService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Don't show and throw error messages for graph requests
        if (request.url.includes('graph')) {
          return of();
        }
        // Handle errors when sending user messages
        if (request.url.includes('user-messages')) {
          // Use regex to parse url to extract conversation id
          const matches = request.url.match(/conversations\/(.*)\/user-messages/);
          if (matches) {
            const conversationId = matches[1];
            this.eventBus.sendUserMessageError.send(conversationId);
          }
        }
        this.sendError(this.extractErrorMessage(error));
        return throwError(() => new Error(JSON.stringify(error)))
      })
    );
  }

  sendError(error: IError):void {
    const key = 'errors'
    const life = 10000; // 10 seconds
    const closable = true;
    const sticky = false;

    this.messageService.add({
      severity: 'error',
      summary: (error.error) ? error.error : '',
      detail: (error.errorDetails) ? error.errorDetails : '',
      key,
      life,
      sticky,
      closable
    })
  }

  private getError(error: HttpErrorResponse): IError {
    let parsedError;
    if (error.error) {
      if (typeof error.error === 'object') {
        parsedError = error.error;
      } else {
          try {
            parsedError = JSON.parse(error.error);
          } catch (e) {
        }
      }

      if (parsedError && (parsedError.error || parsedError.errorDetails)) {
        return parsedError
      }
      return {
        error: error.statusText,
        errorDetails: error.message
      }
    }
    return {
      error: error.statusText,
      errorDetails: error.message
    }
  }

  private extractErrorMessage(error: HttpErrorResponse): IError {
   return this.getError(error);
  }
}

export interface IError {
  error: string;
  errorDetails: string;
}
