import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService, Model} from "../../../../../../../services/api.service";
import {environment} from "../../../../../../../../environments/environment";
import {finalize, of, Subscription} from "rxjs";
import {StreamService} from "../../../../../../../services/stream.service";
import {concatMap} from "rxjs/operators";
import {EventBusService, ITyping} from "../../../../../../../services/event-bus.service";
import {NewProject} from "../../../../../../../interfaces/app.interfaces";

@Component({
  selector: 'app-conversation-item',
  templateUrl: './conversation-item.component.html',
  styleUrls: ['./conversation-item.component.scss'],
})
export class ConversationItemComponent implements OnInit, OnDestroy {

  copyButtonDisabled: boolean = false;

  userInitials = this.apiService.userInitials;
  profilePictureUrl = this.apiService.profilePictureUrl;

  @Input() conversation!: NewProject;
  @Input() conversationItem: IConversationItem = {
    type: ConversationItemType.None,
    message: '',
    date: new Date(),
    id:'',
    conversationId: '',
    model: this.apiService.NotSelectedModel
  };
  @Input() isFirst!: boolean;

  logoPath = './assets/images/chat-ix.png';
  gptLogoPath = './assets/images/icons/regular/gpt-logo-orange-regular.svg';
  palmLogoPath = './assets/images/icons/regular/palm-logo-orange-regular.svg';
  claudeLogoPath = './assets/images/icons/regular/claude-logo-orange-regular.svg';
  geminiLogoPath = './assets/images/icons/small/gemini-logo-orange-small.svg';
  environment = environment;
  ConversationItemType = ConversationItemType;
  chatTyping!: ITyping;
  isCopying: boolean = false;

  // Subscriptions
  dynamicAnswerSubscription!:Subscription;
  chatTypingSubscription!: Subscription;
  private sendUserMessageErrorSubscription!: Subscription;
  private _isSendUserMessageError: boolean = false;

  constructor(
      private apiService: ApiService,
      private streamService: StreamService,
      private eventBus: EventBusService,
      private changeDetector: ChangeDetectorRef,
  ) {

  }

  showUnsavedMessageWarning(conversationItem: IConversationItem): boolean {
    return this._isSendUserMessageError && conversationItem.unsaved === true;
  }

  get showWaitingDots(): boolean {
    return this.isGptTyping();
  }

  // TODO: rework to support another gpt versions
  isGptTyping(): boolean {
    if (this.chatTyping && this.conversation &&
        (this.conversation.model.outputType === 'text')) {
      return this.chatTyping.isTyping && (this.chatTyping.conversationId === this.conversationItem.id)
    }
    return false;
  }

  public copyToClipBoard(message: string): void {
    navigator.clipboard.writeText(message).then();
    this.isCopying = true;
    setTimeout(()=> {
      this.isCopying = false;
    }, 2000);
  }

  isAnswer(type: ConversationItemType): boolean {
    return [ConversationItemType.DynamicAnswer, ConversationItemType.Answer].includes(type)
  }

  public useMessage(message: string): void {
    this.eventBus.newPrompt.next(message);
  }

  public getItemClass(conversationItem: IConversationItem): string {
    if (conversationItem.type === ConversationItemType.Question) {
      return 'question'
    } else if([ConversationItemType.Answer, ConversationItemType.DynamicAnswer].includes(conversationItem.type))  {
      return 'answer'
    }
    return ''
  }

  public getPersonaClass(): string {
    const existingClass = ['first-gradient','second-gradient','third-gradient'];
    if(this.conversation && this.conversation.id) {
      const conversationId = this.conversation.id;
      let hash = 0, i, chr;
      if (conversationId.length === 0) {
        return existingClass[hash];
      }
      for (i = 0; i < conversationId.length; i++) {
        chr = conversationId.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      const gradientNum = Math.abs(hash) % 3;
      return existingClass[gradientNum];
    }
    return existingClass[0];
  }

  getClassForContent(): string {
    let className = 'content ';
    // TODO: remove this after testing
    // if (this.conversation.model.vendor.id === "ab7d7389-64c7-412f-9a61-c6c5ccd1aa2c" ||
    //     this.conversation.model.vendor.id === "0d00d01f-403d-4e61-8140-175cc48efb82") {
    //   if (this.chatTyping && this.chatTyping.conversationId === this.conversationItem.id) {
    //     className = className.concat('answer ');
    //     if (!this.chatTyping.isTyping) {
    //       className = className.concat('stream-end');
    //     }
    //   }
    // }
    return className;
  }

  ngOnInit(): void {

    this.chatTypingSubscription = this.eventBus.chatTyping.subscribe(value => {
      this.chatTyping = value;

      this.copyButtonDisabled = value.isTyping;
        this.changeDetector.detectChanges();

      if (value && !value.isTyping && this.dynamicAnswerSubscription) {
        this.dynamicAnswerSubscription.unsubscribe();
      }
    });


    if (this.conversationItem.type === ConversationItemType.DynamicAnswer) {

      this.eventBus.chatTyping.next({
        conversationId: this.conversationItem.id,
        isTyping: true
      });

      this.dynamicAnswerSubscription = this.streamService.sendStreamingMessage(this.conversationItem.dynamicContent as string, this.conversationItem.conversationId as string, <Model>this.conversationItem.model)
          .pipe(
              finalize(()=> {
                this.eventBus.clearChatTypingObject();
              }),
              concatMap(item => of(item))
          ).subscribe((event:any) => {
              if (event instanceof MessageEvent) {
                if (event.data) {
                  const message = JSON.parse(event.data).message;
                  if (message) {
                    this.conversationItem = {...this.conversationItem, message: this.conversationItem.message.concat(message)}
                  }
                }
              }
              // Assuming we reached and of stream
              if (event instanceof ErrorEvent) {

                this.eventBus.clearChatTypingObject();

                if(this.isFirst) {
                  this.eventBus.updateConversationNameById.send(this.conversation.id);
                }
              }
            },
          )
    }

    this.sendUserMessageErrorSubscription = this.eventBus.sendUserMessageError.subscribe((conversationId) => {
      // delete last user message
      this._isSendUserMessageError = true;
    });

  }

  ngOnDestroy(): void {

    if (this.dynamicAnswerSubscription) {
      this.dynamicAnswerSubscription.unsubscribe();
    }

    if (this.sendUserMessageErrorSubscription) {
      this.sendUserMessageErrorSubscription.unsubscribe();
    }

    this.eventBus.clearChatTypingObject();
  }
}

export enum ConversationItemType {
  Question,
  Answer,
  Info,
  None,
  DynamicAnswer
}

export interface IConversationItem {
  id: string;
  type: ConversationItemType;
  message: string;
  date: Date;
  dynamicContent?: string;
  conversationId?: string;
  model?: Model;
  unsaved?: boolean;
}
