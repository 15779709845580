import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {
  EntityToRename,
  IImageCollection,
  Project,
  ProjectTypeName,
  Selection,
} from "../main/components/sidebar/sidebar.component";
import {NewFavorite, NewProject, ToggleFavoriteEvent} from "../interfaces/app.interfaces";
import {EventBus} from "./models/event-bus.model";
import {ApiService, NotSelectedModel} from "./api.service";


@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  constructor(
      private api: ApiService
  ) { }

  newChat: Subject<null> = new Subject<null>();
  newPrompt: Subject<string> = new Subject<string>();

  selectConversation: Subject<Selection> = new Subject<Selection>();
  selectImageCollection: Subject<Selection> = new Subject<Selection>();
  selectFavorite: Subject<{ selection: Selection, projectType: ProjectTypeName }> = new Subject<{ selection: Selection, projectType: ProjectTypeName }>();

  // New Events
  renameProject = new EventBus<NewProject>();
  deleteProject = new EventBus<NewProject>();
  updateProject = new EventBus<NewProject>();
  updateImageCollectionNameById = new EventBus<string>();
  updateConversationNameById = new EventBus<string>();
  toggleFavoriteProject = new EventBus<ToggleFavoriteEvent>();
  prependImageCollection = new EventBus<NewProject>();
  prependConversation = new EventBus<NewProject>()

  sendUserMessageError = new EventBus<string>();

  public setSelectConversation(selection: Selection):void {
    this.selectConversation.next(selection);
    this.selectImageCollection.next({id:'', model: this.api.NotSelectedModel });
    this.selectFavorite.next({selection:{id:'', model: this.api.NotSelectedModel}, projectType: ProjectTypeName.NotSelected});
  }

  public setSelectImageCollection(selection: Selection):void{
    this.selectImageCollection.next(selection);
    this.selectConversation.next({id: '', model: this.api.NotSelectedModel});
    this.selectFavorite.next({selection:{id:'', model: this.api.NotSelectedModel}, projectType: ProjectTypeName.NotSelected});
  }

  public setSelectFavorite(selection: Selection, projectType: ProjectTypeName): void{
    this.selectFavorite.next({selection, projectType});
    this.selectConversation.next({id: '', model: this.api.NotSelectedModel});
    this.selectImageCollection.next({id:'', model: this.api.NotSelectedModel});
  }

  // Image collection events

  updateImageCollection: Subject<IImageCollection> = new Subject<IImageCollection>();

  // Common events

  sendMessage: Subject<string> = new Subject<string>();

  chatTyping: Subject<ITyping> = new Subject<ITyping>();

  openDropdown: Subject<boolean> = new Subject<boolean>();

  openAbout: Subject<boolean> = new Subject<boolean>();

  openSidebar: Subject<boolean> = new Subject<boolean>();

  // Spinner
  public isLoading = new Subject<LoadingEvent>();

  public setIsLoading(isLoading: boolean, location: SpinnerLocation) {
    this.isLoading.next({
      isLoading,
      location
    })
  }

  public clearChatTypingObject(): void {
    this.chatTyping.next({conversationId: '', isTyping: false});
  }

}

export interface ITyping {
  isTyping: boolean;
  conversationId: string;
}

export interface LoadingEvent {
  isLoading: boolean;
  location: SpinnerLocation;
}


export enum SpinnerLocation {
  Main,
  SideBar,
  Body,
}


export interface RenameData {
  id: string;
  oldName: string;
  entity: EntityToRename;
  initiatedFromFavorites: boolean;
  isFavorite: boolean;
  project: Project;
}

export interface RenameEditedProject {
  project: NewFavorite
  newName: string;
}

export interface FavoriteEvent{
  project: NewProject,
  type: FavoriteEventType
}

export enum FavoriteEventType {
  Add, Remove
}
