import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {Model, NotSelectedModel} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  constructor(
  ) { }

//  public defaultAIModel = new BehaviorSubject<Model>(new NotSelectedModel());
  public selectedAIModel = new BehaviorSubject<Model>(new NotSelectedModel()); //this.api.getDefaultChatModel()

  public lastUsedModel = new BehaviorSubject<Model | undefined>(undefined);

  public updateSidebarConversationItem = new BehaviorSubject<UpdateSidebarConversationItemProps>({
    model: new NotSelectedModel(),
    conversationId: ''
  }); //this.api.getDefaultChatModel()

  public selectedDropdownModel = new BehaviorSubject<Model>(new NotSelectedModel()); //this.api.getDefaultChatModel()


}

interface UpdateSidebarConversationItemProps {
  model: Model;
  conversationId: string;
}