import {Component, OnDestroy, OnInit, TrackByFunction, ViewChild} from '@angular/core';
import {ConversationItemType, IConversationItem} from "./conversation-item/conversation-item.component";
import {
    Conversation,
    IConversationMessage,
    IImage,
    IImageCollection,
    MessageRole
} from "../../../../sidebar/sidebar.component";
import {ApiService, Model} from "../../../../../../services/api.service";
import {EventBusService, SpinnerLocation} from "../../../../../../services/event-bus.service";
import {finalize, of, Subscription, switchMap, tap} from "rxjs";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {AppStateService} from "../../../../../../services/app.state.service";
import {NgxAutoScroll} from "../../../../../directives/ngx-auto-scroll.directive";
import {ImageCollectionService} from "../../../../../../services/image-collection.service";
import {CreateData, DeleteData} from "../coversation-imagecollection/image/image.component";
import {Collection} from "../../../../../../models/collection.model";
import {SidebarSelection, SidebarSelectionService} from "../../../../../../services/sidebar-selection.service";
import {NewProjectTypeName} from "../../../../../../interfaces/app.interfaces";

@Component({
    selector: 'app-chat-main-conversation',
    templateUrl: './chat-main-conversation.component.html',
    styleUrls: ['./chat-main-conversation.component.scss']
})
export class ChatMainConversationComponent implements OnInit, OnDestroy {

    @ViewChild(NgxAutoScroll) ngxAutoScroll!: NgxAutoScroll;

    conversationItems: Collection<IConversationItem> = new Collection<IConversationItem>([], true);
    imageCollection: IImageCollection | undefined;
    images: Collection<IImage> = new Collection<IImage>([], true);
    sendMessageSubscription!: Subscription;
    newChatSubscription!: Subscription;
    selectionSubscription!: Subscription;
    selectedModelSubscription!: Subscription;
    selectedDropdownModelSubscription!: Subscription;
    updateSelectedModelSubscription!: Subscription;

    sendUserMessageErrorSubscription!: Subscription;

    selectedProject?: SidebarSelection;
    selectedModel: Model = this.api.getDefaultChatModel();
    selectedDropdownModel!: Model;

    constructor(
        private api: ApiService,
        private eventBus: EventBusService,
        private stateService: AppStateService,
        private imageService: ImageCollectionService,
        public selection: SidebarSelectionService,
    ) {
    }

    isConversationSelected(): boolean {
        if (this.selectedProject) {
            if (this.selectedProject.projectTypeName === NewProjectTypeName.Conversation) {
                return true;
            }
        }
        return false;
    }

    isImageCollectionSelected(): boolean {
        if (this.selectedProject) {
            if (this.selectedProject.projectTypeName === NewProjectTypeName.ImageCollection) {
                return true;

            }
        }
        return false;
    }

    // TODO: Refactor
    convertModelToText(model: Model): string {
       return model.displayName;
    }

    subscribeToSelection(): Subscription {
        return this.selection.observe().pipe(
            tap((state) => {
                this.selectedProject = state.project;
                this.eventBus.setIsLoading(true, SpinnerLocation.Body);
            }),
            switchMap((state) => {
                if (state && state.project) {
                    switch (state.project.projectTypeName) {

                        case NewProjectTypeName.Conversation: {
                            if (!state.newProject) {
                                return this.api.getConversationById(state.project.id).pipe(
                                    tap(conversation => {
                                        // TODO: rework below code
                                        this.conversationItems.replaceCollection(this.getConversationItems(conversation));
                                        this.selectedModel = conversation.model;
                                    }),
                                    finalize(() => {
                                        this.eventBus.setIsLoading(false, SpinnerLocation.Body);
                                    })
                                )
                            }
                            return of().pipe(
                                finalize(() => {
                                    this.eventBus.setIsLoading(false, SpinnerLocation.Body);
                                })
                            )
                        }

                        case NewProjectTypeName.ImageCollection: {
                            if (!state.newProject) {
                                return this.imageService.getImageCollectionById(state.project.id).pipe(
                                    tap(imageCollection => {
                                        // TODO: rework below code
                                        if (imageCollection) {
                                            this.images.replaceCollection(imageCollection.images.map((image) => {
                                                    return {...image, existing: true, tempId: uuidv4()}
                                                })
                                            )
                                            this.imageCollection = imageCollection;
                                            this.selectedModel = imageCollection.model;
                                            // TODO Rewrite state management from scratch
                                            this.stateService.selectedAIModel.next(state.project.model);
                                        }
                                        // this.images.replaceCollection(this.images.ref.map((image) => {
                                        //         return {...image, existing: true, tempId: uuidv4()}
                                        //     })
                                        // )
                                    }),
                                    finalize(() => {
                                        this.eventBus.setIsLoading(false, SpinnerLocation.Body);
                                    })
                                )
                            }
                            return of().pipe(
                                finalize(() => {
                                    this.eventBus.setIsLoading(false, SpinnerLocation.Body);
                                })
                            )
                        }


                    }

                }
                return of([])
            })
        ).subscribe()
    }

    isConversationOrImageCollectionSelected(): boolean {
        return !!this.isConversationSelected() || !!this.isImageCollectionSelected();
    }


    ngOnInit(): void {
        this.subscribeToSelection();

        this.sendMessageSubscription = this.eventBus.sendMessage.subscribe((message) => {
                this.sendMessage(message);
            }
        );

        this.newChatSubscription = this.eventBus.newChat.subscribe(() => {
                this.startNewPrompt();
            }
        );

        this.selectedModelSubscription = this.stateService.selectedAIModel.subscribe(value => {
            if (value) {
                this.selectedModel = value;
            }
        });

        this.selectedDropdownModelSubscription = this.stateService.selectedDropdownModel.subscribe(value => {
            if (value) {
                this.selectedDropdownModel = value;
            }
        });

        this.sendUserMessageErrorSubscription = this.eventBus.sendUserMessageError.subscribe((conversationId) => {
            // delete last message (it's empty in this case)
            this.conversationItems.delete(this.conversationItems.ref[this.conversationItems.ref.length - 1], 'id');
            // mark last massage as unsaved
            this.conversationItems.ref[this.conversationItems.ref.length - 1].unsaved = true;
            // reset lastUsedModel to fallback to the default one
            this.stateService.lastUsedModel.next(undefined);
        });

    }

    isFirstDynamicAnswer(): boolean {
        return this.conversationItems.ref.filter(
            item => item.type === ConversationItemType.DynamicAnswer
        ).length === 1
    }

    handleCreate(createData: CreateData): void {
        if (this.images) {
            const tempImages = this.images.ref.map(image => {
                if (image.tempId === createData.tempId) {
                    return {
                        ...image,
                        existing: true,
                        id: createData.image.id,
                        imageUrl: `${createData.image.imageUrl}?tempId=${uuidv4()}`,
                        thumbnailUrl: `${createData.image.thumbnailUrl}?tempId=${uuidv4()}`,
                        downloadUrl: createData.image.downloadUrl,
                        size: createData.image.size,
                        generationDate: createData.image.generationDate
                    }
                }
                return image
            })
            this.images.replaceCollection(tempImages);

        }
    }

    handleImageDelete(deleteData: DeleteData): void {
        this.images.delete(deleteData.image, 'tempId')
    }

    startNewPrompt() {
        this.conversationItems.empty();
        this.images.empty();
        this.selectedProject = undefined;
    }

    pushQuestion(message: string, model: Model): void {
        // update last used model
        this.stateService.lastUsedModel.next(this.selectedModel);

        this.conversationItems.append(
            this.convertMessageToConversationItem(
                {
                    id: uuidv4(),
                    model: model,
                    content: message,
                    creationDate: new Date(),
                    author: {
                        role: MessageRole.User
                    }
                }
            )
        )
    }

    pushUpdateMessage(message: string, model: Model): void {
        this.conversationItems.append(
            this.convertMessageToConversationItem(
                {
                    id: uuidv4(),
                    model: model,
                    content: message,
                    creationDate: new Date(),
                    author: {
                        role: MessageRole.Info
                    }
                }
            )
        )
    }

    pushImage(message: string, model: Model): void {
        // update last used model
        this.stateService.lastUsedModel.next(this.selectedModel);

        this.images.append({
            id: '',
            model: model,
            prompt: message,
            size: '0',
            generationDate: new Date(),
            existing: false,
            imageUrl: '',
            tempId: uuidv4(),
            thumbnailUrl: '',
            downloadUrl: ''
        })
    }

    pushDynamicAnswer(message: string, projectId: string, model: Model): void {
        this.conversationItems.append(
            this.convertMessageToConversationItem(
                {
                    id: uuidv4(),
                    model: model,
                    content: '',
                    creationDate: new Date(),
                    author: {
                        role: MessageRole.StreamingAssistant
                    },
                    dynamicContent: message,
                    conversationId: projectId
                }
            )
        )
    }

    changeConversationModelAndPushMessage(prompt: string, projectId: string): Subscription {
        this.eventBus.setIsLoading(false, SpinnerLocation.Body);
        return this.api.changeConversationModel(projectId, this.selectedDropdownModel).pipe(
            finalize(() => {
                this.eventBus.setIsLoading(false, SpinnerLocation.SideBar)
            })
        ).subscribe((conversation) => {
            if (conversation) {
                this.stateService.selectedAIModel.next(this.selectedDropdownModel);
                const updateMessage = `Model changed from "${this.selectedProject?.model.displayName}" to "${this.selectedDropdownModel.displayName}"`;
                this.pushUpdateMessage(updateMessage, this.selectedDropdownModel);
                this.pushQuestion(prompt, this.selectedDropdownModel);
                this.pushDynamicAnswer(prompt, projectId, this.selectedDropdownModel);
                this.selectedProject = conversation;
                this.stateService.updateSidebarConversationItem.next({
                    model: this.selectedDropdownModel,
                    conversationId: conversation.id
                });
            }
            // TODO: Handle failure
        })
    }

    changeImageCollectionModelAndPushImage(prompt: string, projectId: string) {
        this.eventBus.setIsLoading(false, SpinnerLocation.Body);
        return this.api.changeImageCollectionModel(projectId, this.selectedDropdownModel).pipe(
            finalize(() => {
                this.eventBus.setIsLoading(false, SpinnerLocation.SideBar)
            })
        ).subscribe((imageCollection) => {
            if (imageCollection) {
                this.stateService.selectedAIModel.next(this.selectedDropdownModel);
                this.pushImage(prompt, this.selectedDropdownModel);
                this.selectedProject = imageCollection;
                this.stateService.updateSidebarConversationItem.next({
                    model: this.selectedDropdownModel,
                    conversationId: imageCollection.id
                });

            }
            // TODO: Handle failure
        })
    }

    startNewConversation(prompt: string, model: Model): void {
        this.api.createConversation(model).pipe(
            tap(conversation => {
                    this.selectedProject = conversation;
                    this.pushQuestion(prompt, model);
                    this.pushDynamicAnswer(prompt, conversation.id, model);
                    this.eventBus.prependConversation.send(conversation);
                    this.selection.selectProject(conversation, true);
                }
            )
        ).subscribe()
    }

    startNewImageCollection(prompt: string, model: Model): void {
        this.imageService.createImageCollection(model).pipe(
            tap(imageCollection => {
                const project = {...imageCollection, projectTypeName: NewProjectTypeName.ImageCollection};
                this.selectedProject = project;
                this.selection.selectProject(project, true);
                this.pushImage(prompt, model);
                this.eventBus.prependImageCollection.send(project);
            })
        ).subscribe()
    }

    startNewProject(prompt: string): void {
        if (this.stateService.selectedAIModel.value.outputType === 'text') {
            this.startNewConversation(prompt, this.stateService.selectedAIModel.value);
        } else {
            this.startNewImageCollection(prompt, this.stateService.selectedAIModel.value);
        }
    }

    sendMessage(prompt: string): void {
        if (this.selectedProject) {
            //TODO: convert to switch case
            switch (this.selectedProject.projectTypeName) {
                case NewProjectTypeName.Conversation: {
                    if (this.selectedDropdownModel.name !== 'not-selected' && this.selectedDropdownModel.name != this.selectedProject.model.name) {
                        this.changeConversationModelAndPushMessage(prompt, this.selectedProject.id);
                    } else {
                        this.pushQuestion(prompt, this.selectedProject.model);
                        this.pushDynamicAnswer(prompt, this.selectedProject.id, this.selectedProject.model);
                    }
                    break;
                }
                case NewProjectTypeName.ImageCollection: {
                    if (this.selectedDropdownModel && this.selectedDropdownModel.name !== 'not-selected' && this.selectedDropdownModel.name != this.selectedProject.model.name) {
                        this.changeImageCollectionModelAndPushImage(prompt, this.selectedProject.id);
                    } else {
                        this.pushImage(prompt, this.stateService.selectedDropdownModel.value);
                    }
                    break;
                }

                default: {
                    console.error('Error: Unknown Project type name: this.selectedProject.projectTypeName', this.selectedProject.projectTypeName)
                }
            }
        } else {
            this.startNewProject(prompt);
        }
    }

    trackByMessageId: TrackByFunction<IConversationItem> = (index, item) => item.id;
    trackByImageId: TrackByFunction<IImage> = (index, item) => item.id


    convertMessageToConversationItem(message: IConversationMessage, model: Model | undefined = undefined): IConversationItem {
        switch (message.author.role) {
            case MessageRole.User: {
                return {
                    message: message.content,
                    type: ConversationItemType.Question,
                    date: message.creationDate,
                    id: uuidv4(),
                    conversationId: message.conversationId,
                    model: message.model
                }
            }

            case MessageRole.Assistant: {
                return {
                    message: message.content,
                    type: ConversationItemType.Answer,
                    date: message.creationDate,
                    id: uuidv4(),
                    conversationId: message.conversationId,
                    model: message.model
                }
            }

            case MessageRole.Info: {
                return {
                    message: message.content,
                    type: ConversationItemType.Info,
                    date: message.creationDate,
                    id: uuidv4(),
                    conversationId: message.conversationId,
                    model: message.model
                }
            }

            case MessageRole.StreamingAssistant: {
                return {
                    message: message.content,
                    type: ConversationItemType.DynamicAnswer,
                    date: message.creationDate,
                    dynamicContent: message.dynamicContent,
                    conversationId: message.conversationId,
                    id: uuidv4(),
                    model: message.model
                }
            }
        }
    }

    getConversationItems(conversation: Conversation, newModel: Model | undefined = undefined): IConversationItem[] {
        if (this.selectedProject) {
            return conversation.messages.map(message => this.convertMessageToConversationItem(message, newModel))
        }
        return [];
    }


    ngOnDestroy(): void {
        this.sendMessageSubscription.unsubscribe();
        this.newChatSubscription.unsubscribe();
        this.selectionSubscription.unsubscribe();
        this.sendUserMessageErrorSubscription.unsubscribe();
    }

}

export enum VendorType {
    Image,
    Gpt,
    NotSelected
}
