import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {
  Conversation,
  IImage,
  IImageCollection,
  IRandomImageCollection
} from "../main/components/sidebar/sidebar.component";
import {Observable, of} from "rxjs";
import {EventBusService} from "./event-bus.service";
import {NewProject, NewProjectTypeName} from "../interfaces/app.interfaces";
import {Model} from "./api.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ImageCollectionService {

  private readonly endpoint: string =  environment.backend.url;

  constructor(
      private http: HttpClient,
      private eventBus: EventBusService
  ) { }


  deleteImage(imageCollectionId: string, imageId: string) {
    return this.http.delete(`${this.endpoint}/image-collections/${imageCollectionId}/images/${imageId}`)
  }

  getImageRendition(imageCollectionId: string, imageId: string, rendition: Rendition = Rendition.Thumbnail) {
    return this.http.get(`${this.endpoint}/image-collections/${imageCollectionId}/images/${imageId}/renditions/${rendition}`, {responseType:'blob'})
  }


  getImageCollectionById(id: string): Observable<IImageCollection> {
    return this.http.get<IImageCollection>(`${this.endpoint}/image-collections/${id}`);
  };

  regenerateImage(imageCollectionId: string, imageId: string):Observable<IImage> {
    return this.http.post<IImage>(`${this.endpoint}/image-collections/${imageCollectionId}/images/${imageId}/image-regeneration`, null)
  }

  submitImageCreation(collectionId: string, prompt: string, model: Model):Observable<IImage[]> {
    const data  = {
      model: model.name,
      prompt,
      size: "1024x1024",
      imageCount: 1
    }
    return this.http.post<IImage[]>(`${this.endpoint}/image-collections/${collectionId}/images`,data)
  }

  deleteImagesCollection(collectionId: string): Observable<any> {
    return this.http.delete(`${this.endpoint}/image-collections/${collectionId}`)
  }

  createImageCollection(model: Model): Observable<NewProject> {
    const payload = {
      model: model.name
    }
    return this.http.post<NewProject>(`${this.endpoint}/image-collections/`, payload).pipe(
        // TODO: remove this map, when models on backend will be in sync
        map(data => {
          return {...data, projectTypeName: NewProjectTypeName.ImageCollection}
        } )
    );

  }

  getRandomImages(): Observable<IRandomImageCollection> {
    return this.http.get<IRandomImageCollection>(`${this.endpoint}/random-images`);
  }

  renameImageCollection(id: string, title: string): Observable<NewProject> {
    const data = {
      title
    }
    return this.http.patch<NewProject>(`${this.endpoint}/image-collections/${id}`,data);
  }

}


export enum Rendition {
  Original,
  Thumbnail,
  Attachment,
  Web
}
