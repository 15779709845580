import {Component, OnDestroy, OnInit, TrackByFunction} from '@angular/core';
import {EventBusService, RenameEditedProject, SpinnerLocation} from "../../../services/event-bus.service";
import {ApiService, Model} from "../../../services/api.service";
import {finalize, forkJoin, Observable, Subscription, switchMap} from "rxjs";
import {AppStateService} from "../../../services/app.state.service";
import packageJson from '../../../../../package.json';
import {SpinnerPosition} from "../../../shared/spinner/spinner.component";
import {ImageCollectionService} from "../../../services/image-collection.service";
import {BrowserStorageService} from "../../../services/browser-storage.service";
import {DialogService} from "primeng/dynamicdialog";
import {RenameDialogComponent} from "./rename-dialog/rename-dialog.component";
import {Collection} from "../../../models/collection.model";
import {NewFavorite, NewProject, NewProjectTypeName, ToggleFavoriteEvent} from "../../../interfaces/app.interfaces";
import {SidebarSelectionService} from "../../../services/sidebar-selection.service";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {Utils} from "../../../utils/utils";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    providers: [DialogService]
})
export class SidebarComponent implements OnInit, OnDestroy {

    searchValue: string = '';
    conversations: Collection<NewProject> = new Collection<NewProject>([], true);
    imageCollections: Collection<NewProject> = new Collection<NewProject>();
    favorites: Collection<NewFavorite> = new Collection<NewFavorite>([], true)

    updateConversations!: Subscription;
    deleteConversationSubscription!: Subscription;
    updateImageCollections!: Subscription;
    prependConversations!: Subscription;
    updateImageCollectionSubscription!: Subscription;
    updateImageCollectionNameSubscription!: Subscription;
    updateSidebarConversationItem!: Subscription;

    // New events

    renameProjectSubscription!: Subscription;
    deleteProjectSubscription!: Subscription;
    updateProjectSubscription!: Subscription;
    toggleFavoriteProjectSubscription!: Subscription;

    prependNewImageCollectionSubscription!: Subscription;
    updateConversationNameByIdSubscription!: Subscription;

    showSpinner = false;

    newChatButtonOptions!: INewChatButton;

    readonly SpinnerPosition = SpinnerPosition;

    version = packageJson.version;
    protected readonly SpinnerLocation = SpinnerLocation;

    constructor(
        private eventBus: EventBusService,
        private api: ApiService,
        private imageService: ImageCollectionService,
        private stateService: AppStateService,
        private browserStorage: BrowserStorageService,
        public dialogService: DialogService,
        private selection: SidebarSelectionService,
    ) {
    }

    setNewChatButtonOptions(): void {
        const randomValue = Math.floor(Math.random() * 3) + 1;
        if (randomValue.toString() === '1') {
            this.newChatButtonOptions = {
                text: 'Start AI journey',
                icon: 'chat-icon rocket-icon'
            }
        }
        if (randomValue.toString() === '2') {
            this.newChatButtonOptions = {
                text: 'Dive into AI',
                icon: 'chat-icon submarine-icon'
            }
        }
        if (randomValue.toString() === '3') {
            this.newChatButtonOptions = {
                text: 'Dig into AI',
                icon: 'chat-icon excavator-icon'
            }
        }
    }

    startNewChat(): void {
        this.newConversation(this.getModelForNewChat());
        // FOR MOBILE
        this.eventBus.openSidebar.next(false);
    }

    private getModelForNewChat(): Model {
        return this.stateService.lastUsedModel.value ?? this.api.getDefaultChatModel();
    }

    showRenameDialog(project: NewProject) {
        const ref = this.dialogService.open(RenameDialogComponent, {
            header: `Rename ${Utils.getProjectTypeDisplayName(project.projectTypeName)}`,
            width: '650px',
            style: {'margin': '0 20px'},
            data: project
        });

        ref.onClose.subscribe((data: RenameEditedProject) => {
            if (data) {
                this.doRenameEntity(data);
            }
        })
    }

    renameConversation(renameData: RenameEditedProject): void {
        this.api.renameConversation(renameData.project.id, renameData.newName).subscribe(data => {
            this.conversations.replaceItem(data, 'id', {
                projectTypeName: NewProjectTypeName.Conversation,
                isFavorite: renameData.project.isFavorite
            } )
            this.favorites.replaceItem(data, 'id', {
                tempGuid: (renameData.project.tempGuid)? renameData.project.tempGuid : uuidv4(),
                projectTypeName: NewProjectTypeName.Conversation,
                isFavorite: renameData.project.isFavorite
            });

        })
    }

    renameImagesCollection(renameData: RenameEditedProject): void {
        this.imageService.renameImageCollection(renameData.project.id, renameData.newName).subscribe(data => {
            this.imageCollections.replaceItem(data, 'id',{
                projectTypeName: NewProjectTypeName.ImageCollection,
                isFavorite: renameData.project.isFavorite
            });
            this.favorites.replaceItem(data, 'id', {
                tempGuid: (renameData.project.tempGuid)? renameData.project.tempGuid : uuidv4(),
                projectTypeName: NewProjectTypeName.ImageCollection,
                isFavorite: renameData.project.isFavorite
            });
        })
    }

    doRenameEntity(renameEditedData: RenameEditedProject) {
        switch (renameEditedData.project.projectTypeName) {
            case NewProjectTypeName.Conversation: {
                this.renameConversation(renameEditedData);
                return;
            }

            case NewProjectTypeName.ImageCollection: {
                this.renameImagesCollection(renameEditedData)
                return;
            }
        }
    }

    updateProject(project: NewProject): void {
        this.conversations.replaceItem(project, 'id');
        this.imageCollections.replaceItem(project, 'id');
        this.favorites.replaceItem(project, 'id');
    }

    prependNewImageCollection(event: NewProject):void {
        this.prependImageCollection(event);
        this.selection.selectProject(event);
    }

    newConversation(model: Model): void {
        this.stateService.selectedAIModel.next(model);
        this.eventBus.newChat.next(null);
    }

    public userHasAccessToImageCollections(): boolean {
        return true;
    }

    public userHasAccessToConversations(): boolean {
        return true;
    }

    prependImageCollection(imageCollection: NewProject): void {
        this.imageCollections.prepend(imageCollection);
    }

    deleteProject(project: NewProject): void {
        this.conversations.delete(project, 'id');
        this.imageCollections.delete(project, 'id');
        this.favorites.delete(project, 'id');
    }

    handleToggleFavoriteEvent(favoriteEvent: ToggleFavoriteEvent) {
        this.conversations.updateItem(favoriteEvent.project, 'id', {isFavorite: favoriteEvent.status});

        this.imageCollections.updateItem(favoriteEvent.project, 'id',{isFavorite: favoriteEvent.status});
        if (favoriteEvent.status) {
            this.favorites.prepend({...favoriteEvent.project, tempGuid: uuidv4()})
        } else {
            this.favorites.delete(favoriteEvent.project, 'id')
        }
    }

    updateImageCollectionName(id: string):void{
        this.imageService.getImageCollectionById(id).subscribe(imageCollection => {
            this.imageCollections.replaceItem({...imageCollection, projectTypeName: NewProjectTypeName.ImageCollection}, 'id',)
        })
    }

    //Helpers
    trackFavoriteById: TrackByFunction<NewFavorite> = (index, item) => item.tempGuid;

    trackProjectById: TrackByFunction<NewProject> = (index, item) => item.id;

    ngOnInit(): void {
        this.getProjects();
        this.setNewChatButtonOptions();

        // debug lastUsedModel
        // this.stateService.lastUsedModel.subscribe((model) => {
        //     console.log(model?.name);
        // });

        this.updateConversationNameByIdSubscription = this.eventBus.updateConversationNameById.subscribe(projectId => {
            this.api.getConversationProjectById(projectId).subscribe((conversation) => {
                this.conversations.replaceItem({...conversation, projectTypeName: NewProjectTypeName.Conversation}, 'id')
            })
        })

        this.updateSidebarConversationItem = this.stateService.updateSidebarConversationItem.subscribe(props => {
            // Update project item
            const currentProject = this.conversations.ref.filter(item => item.id === props.conversationId);
            const updatedProject = { ...currentProject[0], model: props.model};
            this.conversations.replaceItem(updatedProject, 'id');
            // Update favorite item
            const currentFavorite = this.favorites.ref.filter(item => item.id === props.conversationId);
            const updatedFavorite = { ...currentFavorite[0], model: props.model};
            this.favorites.replaceItem(updatedFavorite, 'id');
        });

        this.renameProjectSubscription = this.eventBus.renameProject.subscribe(event => {
            this.showRenameDialog(event);
        });
        this.deleteProjectSubscription = this.eventBus.deleteProject.subscribe(event => this.deleteProject(event));
        this.updateProjectSubscription = this.eventBus.updateProject.subscribe(event => this.updateProject(event));
        this.toggleFavoriteProjectSubscription = this.eventBus.toggleFavoriteProject.subscribe(event => this.handleToggleFavoriteEvent(event));
        this.updateImageCollectionNameSubscription = this.eventBus.updateImageCollectionNameById.subscribe(event => this.updateImageCollectionName(event));

        this.prependNewImageCollectionSubscription = this.eventBus.prependImageCollection.subscribe(event => this.prependImageCollection(event));

        this.eventBus.isLoading.subscribe((loadingEvent) => {
            if (loadingEvent && loadingEvent.location === SpinnerLocation.SideBar) {
                this.showSpinner = loadingEvent.isLoading;
            }
        })

        this.prependConversations = this.eventBus.prependConversation.subscribe((conversation) => {
            // Adding newly created conversation to the top of the list
            this.conversations.prepend(conversation);
        })

        this.updateImageCollectionSubscription = this.eventBus.updateImageCollection.pipe(
            switchMap(imageCollectionItem => {
                return this.imageService.getImageCollectionById(imageCollectionItem.id)
            })
        ).subscribe((imageCollectionItem) => {
            // this.imageCollections.replaceItem(imageCollectionItem, 'id', {selected: true})
        })
    }

    ngOnDestroy(): void {
        this.updateConversations && this.updateConversations.unsubscribe();
        this.updateImageCollections && this.updateImageCollections.unsubscribe();
        this.deleteConversationSubscription && this.deleteConversationSubscription.unsubscribe();
        this.prependConversations && this.prependConversations.unsubscribe();
        this.updateImageCollectionSubscription && this.updateImageCollectionSubscription.unsubscribe();
        this.prependNewImageCollectionSubscription && this.prependNewImageCollectionSubscription.unsubscribe();
        this.updateImageCollectionNameSubscription && this.updateImageCollectionNameSubscription.unsubscribe();

        // New subscriptions
        this.renameProjectSubscription && this.renameProjectSubscription.unsubscribe();
        this.updateProjectSubscription && this.updateProjectSubscription.unsubscribe();
        this.deleteProjectSubscription && this.deleteProjectSubscription.unsubscribe();
        this.toggleFavoriteProjectSubscription && this.toggleFavoriteProjectSubscription.unsubscribe();
        this.updateConversationNameByIdSubscription && this.updateConversationNameByIdSubscription.unsubscribe()
    }

    private prepareDataCalls(): DataCalls<NewProject, NewFavorite> {
        return {
            "projects": this.api.getProjects(),
            "favorites": this.api.getFavorites()
        };
    }

    private getProjects() {
        const calls = this.prepareDataCalls();
        this.eventBus.setIsLoading(true, SpinnerLocation.SideBar)
        forkJoin<DataCalls<NewProject, NewFavorite>>(calls).pipe(
            finalize(() => {
                this.eventBus.setIsLoading(false, SpinnerLocation.SideBar)
            })
        ).subscribe((data) => {
            const projects = data.projects as NewProject[];
            const favorites = data.favorites as NewFavorite[];

            const filterConversations = (projectType: NewProjectTypeName) => (item:NewProject) =>{
                return item.projectTypeName === projectType
            }

            this.conversations.replaceCollection(projects,true).filterOut(filterConversations(NewProjectTypeName.Conversation));
            this.imageCollections.replaceCollection(projects,true).filterOut(filterConversations(NewProjectTypeName.ImageCollection));
            this.favorites.replaceCollection(favorites);
        })
    }

    showAboutDialog() {
        this.eventBus.openAbout.next(true);
    }
}

export interface Conversation extends Selection, Favorite {
    title: string;
    updateDate?: Date;
    messages: IConversationMessage[];
    model: Model;
    projectTypeName: ProjectTypeName;
    creationDate: Date;
    modificationDate: Date;
}

export interface Project extends Selection, Favorite {
    "id": string;
    model: Model;
    "title": string;
    "creationDate": Date;
    "modificationDate": Date;
    "projectTypeName": ProjectTypeName
}

export interface IImageCollection extends Selection, Favorite {
    owner?: string;
    title: string;
    images: IImage[];
    creationDate: Date;
    projectTypeName: ProjectTypeName;
    modificationDate: Date;
    isLocked: boolean;
}

export interface IImage {
    "id": string;
    "model": Model;
    "prompt": string;
    "size": string;
    "generationDate": Date;
    "existing": boolean;
    "tempId": string;
    "imageUrl": string;
    "thumbnailUrl": string;
    "downloadUrl": string;

}

export interface IRandomImageCollection {
    "images": IImage[];
}

export interface Selection {
    id: string;
    model: Model;
    selected?: boolean;
}

export interface Favorite {
    isFavorite: boolean;
}

export interface IConversationMessage {
    id: string;
    model: Model;
    content: string;
    creationDate: Date;
    author: {
        role: MessageRole
    },
    dynamicContent?: string;
    conversationId?: string;
}

export enum MessageRole {
    User = 'user',
    Assistant = 'assistant',
    Info = 'chatixInfo',
    StreamingAssistant = 'streaming-assistant'
}

type Container = 'projects' | 'favorites';

type DataCalls<C, I> = {
    [key in Container]?: Observable<C[] | I[]>
}

export enum EntityToRename {
    ImagesCollection = 'Image Collection',
    Conversation = 'Conversation'
}

export interface INewChatButton {
    text: string;
    icon: string;
}

export enum ProjectTypeName {
    Conversation = 'Conversation',
    ImageCollection = 'ImageCollection',
    NotSelected = 'NotSelected'
}
