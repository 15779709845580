
import {
    VendorType
} from "../main/components/body/components/chat-main/chat-main-conversation/chat-main-conversation.component";
import {NewProjectTypeName} from "../interfaces/app.interfaces";

export class Utils {
    // static getSelectedVendorType(selectedModel: AIModel): VendorType{
    //     const gptVendors = [AIModel.PALM2, AIModel.OpenAIGPT35, AIModel.OpenAIGPT40, AIModel.OpenAIGPT40Turbo, AIModel.OpenAIGPT4032K];
    //     const imageVendors = [AIModel.DALLE];
    //
    //     if(imageVendors.includes(selectedModel)){
    //         return VendorType.Image
    //     }
    //
    //     if(gptVendors.includes(selectedModel)){
    //         return VendorType.Gpt
    //     }
    //
    //     return VendorType.NotSelected;
    // }

    static getProjectTypeDisplayName(projectTypeName: NewProjectTypeName): string {
        switch (projectTypeName) {
            case NewProjectTypeName.Conversation: {
                return "Conversation"
            }
            case NewProjectTypeName.ImageCollection: {
                return 'Image Collection'
            }
            default: {
                console.error("Can't determine project type name")
                return "Unknown project type"
            }
        }
    }

    static getDeletionKey(projectTypeName: NewProjectTypeName): string {
        switch (projectTypeName) {
            case NewProjectTypeName.Conversation: {
                return 'conversation-key'
            }
            case NewProjectTypeName.ImageCollection: {
                return 'image-collection-key'
            }

            default: {
                console.error("Can't determine project type name")
                return ''
            }
        }
    }
}
