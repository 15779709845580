import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserStorageService {
  constructor() {}

  saveValue(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  retrieveValue(key: string): string {
    return (localStorage.getItem(key)) ? localStorage.getItem(key) as string : '' ;
  }

}
