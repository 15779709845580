import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {EventBusService, ITyping} from "../../../../../../services/event-bus.service";
import {Subscription} from "rxjs";
import {AppStateService} from "../../../../../../services/app.state.service";
import packageJson from "../../../../../../../../package.json";
import {SidebarSelectionService} from "../../../../../../services/sidebar-selection.service";
import {ApiService, Model} from "../../../../../../services/api.service";
import {NewProject} from "../../../../../../interfaces/app.interfaces";

@Component({
  selector: 'app-chat-main-prompt',
  templateUrl: './chat-main-prompt.component.html',
  styleUrls: ['./chat-main-prompt.component.scss']
})
export class ChatMainPromptComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() openDropdown: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('input') input!: ElementRef;
  
  version = packageJson.version;
  newChatSubscription!: Subscription;
  newPromptSubscription!: Subscription;
  chatTypingSubscription!: Subscription;
  selectedModelSubscription!: Subscription;
  selectedProjectSubscription!: Subscription;
  selectedDropdownModelSubscription!: Subscription;
  selectedDropdownModel!: Model;
  promptMessage: string = '';
  IsUnavailableModelSelected: boolean = false;

  selectedModel: Model = this.api.getDefaultChatModel();
  selectedProject: NewProject | null = null;
  chatTyping!: ITyping;

  constructor(
      private api: ApiService,
      private eventBus: EventBusService,
      private state: AppStateService,
      private selection: SidebarSelectionService
  ) { }

  IsPromptAvailable(): boolean {
    // block prompt for bot conversations
    if (this.selectedProject && this.selectedProject.isLocked) {
      return false;
    }
    // block prompt when is not selected
    else if (this.selectedProject && this.selectedDropdownModel?.name === "not-selected") {
      return false;
    }

    // allow prompt in all other cases
    return true;
  }

  isGptTyping(): boolean {
    return this.chatTyping && this.chatTyping.isTyping;
  }

  stopTyping() {
    if(this.chatTyping) {
      this.eventBus.updateConversationNameById.send(<string>this.selectedProject?.id);
      this.eventBus.clearChatTypingObject();
    }
  }

  isSendButtonDisabled(): boolean{
    return this.promptMessage.trim().length === 0;
  }

  sendPromptMessage(): void {
    if (this.promptMessage.trim().length > 0) {
      this.eventBus.sendMessage.next(this.promptMessage);
      this.promptMessage = '';
    }
  }

  keydownHandler(event: any): void {
    event.preventDefault();
  }

  onChangeHandler(event: any): void {
    if (this.input.nativeElement.offsetHeight >= 200) {
      this.input.nativeElement.setAttribute('style', 'overflow-y: scroll!important');
    }
  }

  focusInput() {
    if (this.input) {
      setTimeout(() => {
        this.input?.nativeElement?.focus();
      })
    }
  }

  newMessage() {
    this.promptMessage = '';
    this.focusInput();
  }

  openDropdownHandler(): void {
    this.eventBus.openDropdown.next(true);
  }

  isCurrentModelExisting(selectedModel: Model): void {
    this.IsUnavailableModelSelected = !(this.api.contextData?.availableModels.flatMap(group => group.models).find(m => m.name === selectedModel.name) != null);
  }

  ngOnInit(): void {

    this.newPromptSubscription = this.eventBus.newPrompt.subscribe(message => {
      this.promptMessage = message;
    });

    this.newChatSubscription = this.eventBus.newChat.subscribe(() => {
        this.selectedProject = null;
        this.newMessage();
    });

    this.chatTypingSubscription = this.eventBus.chatTyping.subscribe(value => {
      this.chatTyping = value;
      if (!this.chatTyping.isTyping) {
        this.focusInput();
      }
    });

    this.selectedProjectSubscription = this.selection.observe().subscribe((state) => {
      if(state && state.project) {
        this.selectedModel = state.project.model;
        this.selectedProject = state.project;
        this.promptMessage = '';
        this.isCurrentModelExisting(this.selectedModel);
      }
      if (this.chatTyping && !this.chatTyping.isTyping) {
        this.focusInput();
      }
    });

    this.selectedModelSubscription = this.state.selectedAIModel.subscribe(value => {
      this.selectedModel = value;
      this.isCurrentModelExisting(this.selectedModel);
    });

    this.selectedDropdownModelSubscription = this.state.selectedDropdownModel.subscribe(value => {
      if (value) {
        this.selectedDropdownModel = value;
      }
      if (this.chatTyping && !this.chatTyping.isTyping) {
        this.focusInput();
      }
    });

  }

  getPromptMessage(): string{
    if (this.selectedModel.outputType === 'text') {
      return 'Send a message'
    } else if (this.selectedModel.outputType === 'image') {
      return 'Send a prompt'
    }

    return '';
  }

  ngAfterViewInit(): void {
    this.newMessage();
  }

  ngOnDestroy(): void {
    this.newChatSubscription.unsubscribe();
    this.chatTypingSubscription.unsubscribe();
    this.selectedModelSubscription.unsubscribe();
    this.selectedProjectSubscription.unsubscribe();
  }

}
