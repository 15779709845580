import {Component, ElementRef, OnDestroy, OnInit, signal, ViewChild} from '@angular/core';
import {ApiService, ContextData} from "../services/api.service";
import {delay, finalize, Subscription, tap} from "rxjs";
import {AppStateService} from "../services/app.state.service";
import {SpinnerLocation, EventBusService} from "../services/event-bus.service";
import {UserProfileService} from "../services/user-profile.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {MenuItem} from "primeng/api";
import {Avatar} from "primeng/avatar";
import {MsalService} from "@azure/msal-angular";
import {AboutContentComponent} from "./components/dialogs/about/about-content.component";
import {HelpContentComponent} from "./components/dialogs/help/help-content.component";
import packageJson from "../../../package.json";
import {DialogService} from "primeng/dynamicdialog";
import {BrowserStorageService} from "../services/browser-storage.service";

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    providers: [DialogService]
})
export class MainComponent implements OnInit, OnDestroy {

    logoPath = './assets/images/chat-ix.png';
    items: MenuItem[] = [];
    readonly STORAGE_VERSION_KEY = 'chatix.seenVersion';
    version = packageJson.version;

    showSpinner: boolean = false;
    loadingSubscription!: Subscription;
    openSidebarSubscription!: Subscription;

    sidebarVisible: boolean = false;

    constructor(
        public apiService: ApiService,
        public stateService: AppStateService,
        private eventBus: EventBusService,
        private profileService: UserProfileService,
        private sanitizer: DomSanitizer,
        public msal: MsalService,
        public dialogService: DialogService,
        private browserStorage: BrowserStorageService,
    ) {
    }

    getContext(): void {
        this.apiService.getContext()
            .pipe(
                tap(() => {
                    this.eventBus.setIsLoading(true, SpinnerLocation.Main)
                }),
                finalize(() => {
                    this.eventBus.setIsLoading(false, SpinnerLocation.Main)
                })
            )
            .subscribe(
                response => {
                    this.apiService.contextData = response;
                    this.setMenuItems(this.apiService.contextData.email);
                    this.apiService.userInitials.set(this.getUserInitials(response));
                    // TODO: Find a better place for this call
                    this.stateService.selectedAIModel.next(this.apiService.getDefaultChatModel());
                }
            )
    }

    signOut(): void {
        this.msal.logout();
    }

    setMenuItems(userEmail: string): void {
        this.items = [{
            label: userEmail,
            items: [
                {
                    label: 'About Chat-iX',
                    icon: 'pi pi-info-circle',
                    command: () => {
                        this.aboutModal();
                    }
                },
                {
                    label: 'Help',
                    icon: 'pi pi-question-circle',
                    command: () => {
                        this.helpModal();
                    }
                },
                {
                    label: 'Sign out',
                    icon: 'pi pi-sign-out',
                    command: () => {
                        this.signOut();
                    }
                },
            ]
        },
        ];
    }

    ngOnInit(): void {
        this.getContext();
        this.getProfilePicture();
        this.loadingSubscription = this.eventBus.isLoading.subscribe(
            loadingEvent => {
                if (loadingEvent && (loadingEvent.location === SpinnerLocation.Main)) {
                    this.showSpinner = loadingEvent.isLoading;
                }

            }
        )
        this.openSidebarSubscription = this.eventBus.openSidebar.subscribe(
            value => {
                this.sidebarVisible = value;
            }
        )
    }

    ngOnDestroy() {
        this.openSidebarSubscription.unsubscribe();
        this.loadingSubscription.unsubscribe();
    }

    private getProfilePicture(): void {
        this.profileService.getUserProfilePicture().subscribe({
            next: (blob) => {
                const blobUrl = URL.createObjectURL(blob);
                this.apiService.profilePictureUrl.set(this.sanitizer.bypassSecurityTrustUrl(blobUrl));
            },
            error: (error) => console.error('There was an error retrieving profile picture', error)
        });
    }

    private getAboutDialogHeader(): string {
        const value = this.browserStorage.retrieveValue(this.STORAGE_VERSION_KEY);
        if (this.version !== value) {
            return `Welcome to Chat-iX`
        }
        return `Chat-iX Version ${this.version} by N-iX OTPS`;
    }

    private aboutModal(): void {
        const ref = this.dialogService.open(AboutContentComponent, {
            header: this.getAboutDialogHeader(),
            contentStyle: {
                "overflow": "auto",
                "width": "864px",
            },
        });
        ref.onClose.subscribe(() => {
            this.browserStorage.saveValue(this.STORAGE_VERSION_KEY, this.version)
        });
    }

    private helpModal(): void {
        const ref = this.dialogService.open(HelpContentComponent, {
            header: 'Help',
            contentStyle: {
                "overflow": "auto",
                "width": "800px",
                "height": "60vh"
            },
        });
        ref.onClose.subscribe(() => {

        });
    }

    private getUserInitials(context: ContextData) {
        if (context) {
            const firstNameFirstLetter = (context.firstName) ? context.firstName.charAt(0).toUpperCase() : '';
            const lastNameFirstLetter = (context.lastName) ? context.lastName.charAt(0).toUpperCase() : '';
            return `${firstNameFirstLetter}${lastNameFirstLetter}`;
        }
        return '';
    }
}
