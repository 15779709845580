<div class="relative flex flex-column h-full relative">
    <div class="conversation">
        <app-chat-main-conversation></app-chat-main-conversation>
    </div>
    <div class="prompt flex justify-content-center">
        <app-chat-main-prompt
            class="flex align-items-center prompt-component">
        </app-chat-main-prompt>
    </div>

    <ng-container *ngIf="showSpinner">
        <app-spinner [spinnerPosition]="SpinnerPosition.Absolute"></app-spinner>
    </ng-container>
</div>
