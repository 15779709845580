import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration, MsalService,
  MsalRedirectComponent
} from "@azure/msal-angular";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { MainComponent } from './main/main.component';
import {environment} from "../environments/environment";
import { LoginFailedComponent } from './login-failed/login-failed.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ColorPickerModule} from "primeng/colorpicker";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import { HeaderComponent } from './main/components/header/header.component';
import { SidebarComponent } from './main/components/sidebar/sidebar.component';
import { BodyComponent } from './main/components/body/body.component';
import { ChatMainComponent } from './main/components/body/components/chat-main/chat-main.component';
import { ChatMainPromptComponent } from './main/components/body/components/chat-main/chat-main-prompt/chat-main-prompt.component';
import { ChatMainConversationComponent } from './main/components/body/components/chat-main/chat-main-conversation/chat-main-conversation.component';
import {ButtonModule} from "primeng/button";
import { ConversationItemComponent } from './main/components/body/components/chat-main/chat-main-conversation/conversation-item/conversation-item.component';
import {AvatarModule} from "primeng/avatar";
import {MenuModule} from "primeng/menu";
import {TooltipModule} from "primeng/tooltip";
import {DropdownModule} from "primeng/dropdown";
import {DividerModule} from "primeng/divider";
import {RippleModule} from "primeng/ripple";
import {SpinnerComponent} from "./shared/spinner/spinner.component";
import {MarkdownModule} from "ngx-markdown";
import {NgxAutoScroll} from "./main/directives/ngx-auto-scroll.directive";
import { ConversationFeatureComponent } from './main/components/body/components/chat-main/chat-main-conversation/conversation-feature/conversation-feature.component';
import {CardModule} from "primeng/card";
import {AccordionModule} from "primeng/accordion";
import {ErrorNotifierInterceptor} from "./interceptors/error-notifier.interceptor";
import {ToastModule} from "primeng/toast";
import {ConfirmationService, MessageService} from "primeng/api";
import {SelectButtonModule} from "primeng/selectbutton";
import {InputTextareaModule} from "primeng/inputtextarea";
import { CoversationImagecollectionComponent } from './main/components/body/components/chat-main/coversation-imagecollection/coversation-imagecollection.component';
import {DataViewModule} from "primeng/dataview";
import { ImageComponent } from './main/components/body/components/chat-main/coversation-imagecollection/image/image.component';
import {ImageModule} from "primeng/image";
import {SidebarModule} from "primeng/sidebar";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { ConversationFeatureImageComponent } from './main/components/body/components/chat-main/chat-main-conversation/conversation-feature/conversation-feature-image/conversation-feature-image.component';
import {DialogModule} from "primeng/dialog";
import {DynamicDialogModule} from "primeng/dynamicdialog";
import { RenameDialogComponent } from './main/components/sidebar/rename-dialog/rename-dialog.component';
import {SpeedDialModule} from "primeng/speeddial";
import { SidebarProjectComponent } from './main/components/sidebar/sidebar-project/sidebar-project.component';
import { SidebarFavoriteComponent } from './main/components/sidebar/sidebar-favorite/sidebar-favorite.component';
import { HelpContentComponent } from './main/components/dialogs/help/help-content.component';
import { ConversationToggleComponent } from './main/components/body/components/chat-main/chat-main-conversation/conversation-toggle/conversation-toggle.component';
import {AvatarComponent} from "./shared/avatar/avatar.component";
import {TagModule} from "primeng/tag";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.clientId,
      authority: environment.msalConfig.authority,
      redirectUri: environment.msalConfig.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set(environment.backend.url, [environment.msalConfig.scope]);
    protectedResourceMap.set("https://graph.microsoft.com", ["https://graph.microsoft.com/User.Read"]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.msalConfig.scope]
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginFailedComponent,
    HeaderComponent,
    SidebarComponent,
    BodyComponent,
    ChatMainComponent,
    ChatMainPromptComponent,
    ChatMainConversationComponent,
    ConversationItemComponent,
    SpinnerComponent,
    NgxAutoScroll,
    ConversationFeatureComponent,
    CoversationImagecollectionComponent,
    ImageComponent,
    ConversationFeatureImageComponent,
    RenameDialogComponent,
    SidebarProjectComponent,
    SidebarFavoriteComponent,
    ConversationToggleComponent
  ],
    imports: [
        MarkdownModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ColorPickerModule,
        InputTextModule,
        ButtonModule,
        AvatarModule,
        MenuModule,
        TooltipModule,
        DropdownModule,
        DividerModule,
        RippleModule,
        CardModule,
        AccordionModule,
        ToastModule,
        SelectButtonModule,
        InputTextareaModule,
        DataViewModule,
        ImageModule,
        SidebarModule,
        ConfirmDialogModule,
        DialogModule,
        DynamicDialogModule,
        SpeedDialModule,
        AvatarComponent,
        TagModule,
    ],
  providers: [
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorNotifierInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ConfirmationService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
