<div class="help-wrapper">

    <div class="help-item">
        <div class="help-title">Access to the application</div>
        <div class="help-item__wrapper">
            <p>Once you are logged into the application using your domain credentials, you will be redirected to the starting page:</p>
            <img src="../../../../../assets/images/help/01.png" alt="image-01">
            <p>To log out of the application, go to your account section in the top right corner and press Sign-out:</p>
            <img src="../../../../../assets/images/help/02.png" alt="image-02">
        </div>
    </div>

    <div class="help-item">
        <div class="help-title">Initiating a conversation</div>
        <div class="help-item__wrapper">
            <p>On the main page, in order to start a conversation, select a system that you want to use - GPT 3.5 Turbo, GPT 4.0 Turbo, PaLM 2, Claude 3 Opus, Claude 3 Sonnet, Claude 3 Haiku, etc. Type your request and press the send button:</p>
            <img src="../../../../../assets/images/help/03.png" alt="image-03">
            <p>While navigating the application, you can always start a new conversation by pressing the button in the top left corner of the screen.</p>
            <img src="../../../../../assets/images/help/04.png" alt="image-04">
            <p>The newly-initiated conversation will be added to the list of conversations on the left-hand side of the app. It has the name of your conversation:</p>
            <img src="../../../../../assets/images/help/05.png" alt="image-05">
            <p>Please note that the conversations initiated through different systems have different icons:</p>
            <img src="../../../../../assets/images/help/06.png" alt="image-06">
            <p>Each response provided by the app can be copied to the clipboard by pressing the corresponding button:</p>
            <img src="../../../../../assets/images/help/07.png" alt="image-07">
            <p>The responses that are being generated can be stopped by pressing the <b>Stop generating</b> button. The response is stopped immediately after that.</p>
            <img src="../../../../../assets/images/help/08.png" alt="image-08">
            <p>In case you need to run the same request within the current conversation, press the “Use message” button. Your initial request will be populated into the input field:</p>
            <img src="../../../../../assets/images/help/09.png" alt="image-09">
            <p>During the chat, you can change the model you are communicating with. To change a model, choose a new one from the dropdown list and send a prompt.</p>
            <img src="../../../../../assets/images/help/10.png" alt="image-10">
        </div>
    </div>

    <div class="help-item">
        <div class="help-title">Creating an image collection</div>
        <div class="help-item__wrapper">
            <p>To create an image collection, select DALL-E 2 or DALL-E 3 from the new chat dropdown menu and enter your prompt at the bottom of the page:</p>
            <img src="../../../../../assets/images/help/11.png" alt="image-11">
            <p>The newly-created image collection will be displayed on the left-hand side of the app, and it will contain a generated image. The number of images in the image collection is not limited.</p>
            <img src="../../../../../assets/images/help/12.png" alt="image-12">
            <p>Each generated image has the following actions:</p>
            <ul>
                <li><b>Copy prompt</b> - copy the prompt used for the image generation to clipboard;</li>
                <li><b>Use prompt</b> - populate the prompt used for the image generation to the input field;</li>
                <li><b>Regenerate</b> - generate an image with the same prompt one more time;</li>
                <li><b>Download</b> - download the image to user’s PC;</li>
                <li><b>Delete</b> - delete the image from the image collection.</li>
            </ul>
            <img src="../../../../../assets/images/help/13.png" alt="image-13">
        </div>
    </div>

    <div class="help-item">
        <div class="help-title">Actions with the conversations and image collections</div>
        <div class="help-item__wrapper">
            <p>There are the following actions that can be performed with the conversations and image collections:</p>
            <h4>Rename the conversation/image collection</h4>
            <p>To change the name of the conversation/image collection, press the <b>Rename</b> button in the context menu of the conversation and enter a new name there:</p>
            <img class="small" src="../../../../../assets/images/help/14.png" alt="image-14">
            <img src="../../../../../assets/images/help/15.png" alt="image-15">
            <p>The changes can be saved by pressing the Rename button in the Rename conversation modal window. To discard the changes, press the Cancel button.</p>
            <h4>Delete the conversation/image collection</h4>
            <p>To delete the conversation/image collection, press the corresponding button in the context menu:</p>
            <img class="small" src="../../../../../assets/images/help/16.png" alt="image-16">
            <p>Confirm your intention by pressing Yes in the modal window that appears:</p>
            <img class="small" src="../../../../../assets/images/help/17.png" alt="image-17">
            <h4>Adding conversations and image collections to Favorites</h4>
            <p>The conversations and image collections can be added to Favorites section by pressing the <b>Add to Favorites</b> button in the context menu:</p>
            <img src="../../../../../assets/images/help/18.png" alt="image-18">
            <p>After that, the conversation/image collection will be displayed in the Favorites section:</p>
            <img src="../../../../../assets/images/help/19.png" alt="image-19">
            <p>To remove the conversation/image collection from Favorites, press the Remove Favorite button in the context menu:</p>
            <img src="../../../../../assets/images/help/20.png" alt="image-20">
        </div>
    </div>
    
</div>
