<ng-container *ngIf="apiService.contextData">
    <div class="main">
        <div class="header">
            <app-header></app-header>
        </div>
        <div class="wrapper">
            <div class="sidebar">
                <app-sidebar class="h-full"></app-sidebar>
            </div>
            <div class="sidebar-mobile">
                <p-sidebar [(visible)]="sidebarVisible">
                    <ng-template pTemplate="header">
                          <div class="w-full flex align-content-center justify-content-between pr-2">
                              <div class="chat-logo">
                                  <img class="" [src]="logoPath" alt="logo">
                              </div>
                              <div class="user-menu">
                                  <i
                                      class="pi pi-cog"
                                      (click)="menu.toggle($event)"
                                  ></i>
                                  <p-menu #menu appendTo="body" [popup]="true" [model]="items"></p-menu>
                              </div>
                          </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <ng-container *ngIf="sidebarVisible">
                            <app-sidebar class="h-full"></app-sidebar>
                        </ng-container>
                    </ng-template>
                </p-sidebar>
                <p-button class="mobile-btn" (click)="sidebarVisible = true" icon="pi pi-bars"></p-button>
            </div>
            <div class="body">
                <app-body class="h-full"></app-body>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="showSpinner">
    <app-spinner></app-spinner>
</ng-container>
