<p-confirmDialog key="conversation-key" header="Delete Conversation"></p-confirmDialog>
<p-confirmDialog key="image-collection-key" header="Delete Image Collection"></p-confirmDialog>

<div class="conversation">

    <div class="search-wrapper">
        <button
            pButton
            icon="chat-icon plus-icon"
            iconPos="left"
            label="New chat"
            (click)="startNewChat()"
        ></button>
    </div>

    <div class="sidebar-container">
        <p-accordion [multiple]="true">
            <p-accordionTab header="Favorites" [selected]="true" *ngIf="favorites.ref.length > 0">
                <div class="conversation-wrapper">
                    <div class="conversation-list">
                        <ng-container *ngFor="let item of favorites.ref; trackBy: trackFavoriteById">
                            <app-sidebar-favorite
                                [project]="item">
                            </app-sidebar-favorite>
                        </ng-container>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="Conversations" [selected]="true" *ngIf="conversations.ref.length > 0;">
                <div class="conversation-wrapper">
                    <div class="conversation-list">
                        <ng-container *ngFor="let item of conversations.ref; trackBy: trackProjectById">
                            <app-sidebar-project
                                [project]="item"
                            >
                            </app-sidebar-project>
                        </ng-container>
                    </div>
                </div>
            </p-accordionTab>
            <ng-container *ngIf="userHasAccessToImageCollections()">
                <p-accordionTab header="Image Collections" [selected]="true" *ngIf="imageCollections.ref.length > 0">
                    <div class="image-collection-wrapper">
                        <div class="image-collection-list">
                            <ng-container *ngFor="let item of imageCollections.ref;  trackBy: trackProjectById">
                                <app-sidebar-project
                                        [project]="item"
                                >
                                </app-sidebar-project>
                            </ng-container>
                        </div>
                    </div>
                </p-accordionTab>
            </ng-container>
        </p-accordion>
    </div>

    <div class="version-info">
        <div><span style="cursor: pointer" (click)="showAboutDialog()"><span class="corporate-color">Chat-iX</span> Version {{version}}</span> by <a href="https://www.n-ix.com/opentext-consulting-services/" target="_blank" >N-iX OTPS</a> </div>
    </div>

    <ng-container *ngIf="showSpinner">
        <app-spinner [spinnerPosition]="SpinnerPosition.Absolute"></app-spinner>
    </ng-container>
</div>
