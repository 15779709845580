import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TrackByFunction} from '@angular/core';
import {IImage, IImageCollection} from "../../../../sidebar/sidebar.component";
import {CreateData, DeleteData} from "./image/image.component";
import {NewProject} from "../../../../../../interfaces/app.interfaces";

@Component({
  selector: 'app-coversation-imagecollection',
  templateUrl: './coversation-imagecollection.component.html',
  styleUrls: ['./coversation-imagecollection.component.scss']
})
export class CoversationImagecollectionComponent implements OnInit {

  constructor() { }

  @Input() images: IImage[] = [];
  @Input() imageCollection?: NewProject;

  @Output() onCreate: EventEmitter<CreateData> = new EventEmitter<CreateData>();
  @Output() onDelete: EventEmitter<DeleteData> = new EventEmitter<DeleteData>();
  trackByImageId: TrackByFunction<IImage> = (index, item) => item.tempId

  ngOnInit(): void {
  }

  isFirstImage():boolean {
    return this.images.length === 1;
  }

  handleCreate(createData: CreateData): void {
    this.onCreate.emit(createData);
  }

  handleDelete(deleteData:DeleteData): void {
    this.onDelete.emit(deleteData);
  }


}
