<ng-container *ngIf="project">
    <div [ngClass]="isSelected ? 'item-wrapper selected' : 'item-wrapper'">
        <button
                pButton
                pRipple
                type="button"
                (click)="selectConversation()"
                class="conv-button p-button-text p-button-plain"
        >
            <ng-container>
                <img class="chat-logo" [showDelay]="1000" tooltipPosition="top" [pTooltip]="project.model.displayName" [src]="icons[project.model.name]" alt="logo">
            </ng-container>
            <span class="chat-text" [showDelay]="1000" tooltipPosition="top" [pTooltip]="project.title">{{project.title}}</span>
        </button>
        <button
                *ngIf="isSelected"
                pButton
                type="button"
                icon="pi pi-ellipsis-v"
                (click)="menu.toggle($event)"
                class="menu-btn p-button-danger p-button-text"
        >
        </button>
        <p-menu styleClass="sidebar-menu" #menu appendTo="body" [popup]="true" [model]="menuItems"></p-menu>
    </div>
    <p-confirmDialog key="conversation-item" header="Delete Conversation"></p-confirmDialog>


</ng-container>

