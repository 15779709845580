import {Component, OnInit} from '@angular/core';
import {ComingSoon, Feature, IFeature, IFeatureBase} from "./feature";
import {finalize, Subscription} from "rxjs";
import {IImage} from "../../../../../sidebar/sidebar.component";
import {AppStateService} from "../../../../../../../services/app.state.service";
import {EventBusService, SpinnerLocation} from "../../../../../../../services/event-bus.service";
import {ImageCollectionService} from "../../../../../../../services/image-collection.service";
import {ApiService, Model} from "../../../../../../../services/api.service";

@Component({
  selector: 'app-conversation-feature',
  templateUrl: './conversation-feature.component.html',
  styleUrls: ['./conversation-feature.component.scss']
})
export class ConversationFeatureComponent implements OnInit {

  feature: IFeature[] = Feature;
  comingSoon: IFeatureBase = ComingSoon;

  selectedModelSubscription!: Subscription;
  // selectedModel: AIModel = AIModel.OpenAIGPT40;

  selectedModel: Model | undefined;

  randomImages: IImage[] = [];

  // chatAIModels = [
  //   {
  //     icon: 'chat-icon gpt-icon-blue',
  //     label: 'OpenAI GPT-4.0',
  //     value: AIModel.OpenAIGPT40
  //   },
  //   {
  //     icon: 'chat-icon gpt-icon-blue',
  //     label: 'OpenAI GPT-4.0 Turbo',
  //     value: AIModel.OpenAIGPT40Turbo
  //   },
  //   {
  //     icon: 'chat-icon gpt-icon-blue',
  //     label: 'OpenAI GPT-4.0 32k',
  //     value: AIModel.OpenAIGPT4032K
  //   },
  //   {
  //     icon: 'chat-icon gpt-icon-green',
  //     label: 'OpenAI GPT-3.5',
  //     value: AIModel.OpenAIGPT35
  //   },
  //   {
  //     icon: 'chat-icon palm-icon-original',
  //     label: 'Google PaLM 2',
  //     value: AIModel.PALM2
  //   },
  //   {
  //     icon: 'chat-icon gpt-icon-purple',
  //     label: 'OpenAI DALL·E',
  //     value: AIModel.DALLE
  //   }
  // ]
  //
  // chatAIModelsMobile = [
  //   {
  //     label: 'GPT-4.0',
  //     value: AIModel.OpenAIGPT40
  //   },
  //   {
  //     label: 'GPT-4.0 Turbo',
  //     value: AIModel.OpenAIGPT40Turbo
  //   },
  //   {
  //     label: 'GPT-4.0 32k',
  //     value: AIModel.OpenAIGPT4032K
  //   },
  //   {
  //     label: 'GPT-3.5',
  //     value: AIModel.OpenAIGPT35
  //   },
  //   {
  //     label: 'PaLM 2',
  //     value: AIModel.PALM2
  //   },
  //   {
  //     label: 'DALL·E',
  //     value: AIModel.DALLE
  //   }
  // ]

  constructor(
      public appService: ApiService,
      private state: AppStateService,
      private eventBus: EventBusService,
      private imagesApi: ImageCollectionService
  ) { }

  onModelChange(event: any): void {
    this.state.selectedAIModel.next(event.value);
    this.eventBus.newChat.next(null);
  }

  getRandomImages(): void {
    this.eventBus.setIsLoading(true, SpinnerLocation.Body);
    this.imagesApi.getRandomImages().pipe(
        finalize(() => {
          this.eventBus.setIsLoading(false, SpinnerLocation.Body)
        })
    ).subscribe((response) => {
      if (response) {
        this.randomImages = response.images.slice(0, 3);
      }
    })
  }

  ngOnInit(): void {
    this.selectedModelSubscription = this.state.selectedAIModel.subscribe(value => {
      if (value) {
        this.selectedModel = value;
      }
    });
    this.getRandomImages();
  }

}
