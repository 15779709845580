<app-conversation-toggle
    [isConversationSelected]="isConversationSelected()"
    [isImageCollectionSelected]="isImageCollectionSelected()"
>
</app-conversation-toggle>

<div *ngIf="!isConversationOrImageCollectionSelected()" class="conversation-wrapper feature">
    <app-conversation-feature
            class="w-full h-full flex overflow-hidden"
    ></app-conversation-feature>
</div>

<div #scrollSection *ngIf="selectedProject && isConversationSelected()" [lock-y-offset]=10 class="conversation-wrapper" ngx-auto-scroll observe-attributes>
    <div class="conversation-header-info">
        <div class="lock-icon">
            <i
                    [showDelay]="1000"
                    class="chat-icon lock-icon"
                    pTooltip="Secure Chat: Encryption ensures your conversation stays private. Your eyes only."
                    tooltipPosition="left"
            ></i>
        </div>
    </div>
    <div class="mt-7 md:mt-8">
        <ng-container *ngFor="let item of conversationItems.ref; trackBy: trackByMessageId">
            <app-conversation-item
                    [conversationItem]="item"
                    [conversation]="selectedProject"
                    [isFirst]="isFirstDynamicAnswer()"
            >
            </app-conversation-item>
        </ng-container>
    </div>
    <ng-container *ngIf="conversationItems.ref.length == 0">
        <span class="empty-chat-message">This chat has no messages yet.</span>
    </ng-container>
</div>


<div #scrollSection *ngIf="selectedProject && isImageCollectionSelected()" [lock-y-offset]=10 class="conversation-wrapper" ngx-auto-scroll observe-attributes>
    <div class="images-header-info"></div>
    <div class="mt-7">
        <app-coversation-imagecollection
                (onCreate)="handleCreate($event)"
                (onDelete)="handleImageDelete($event)"
                [imageCollection]="selectedProject"
                [images]="images.ref"
                class="h-full block"
        >
        </app-coversation-imagecollection>
    </div>
</div>




