import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {
  ComingSoon,
  Feature,
  IFeature, IFeatureBase
} from "../../body/components/chat-main/chat-main-conversation/conversation-feature/feature";
import {AccordionModule} from "primeng/accordion";
import {DatePipe, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-about',
  standalone: true,
  templateUrl: './about-content.component.html',
  imports: [
    AccordionModule,
    DatePipe,
    NgForOf,
    NgIf
  ],
  styleUrls: ['./about-content.component.scss']
})

export class AboutContentComponent implements OnInit {
  feature: IFeature[] = Feature;
  comingSoon: IFeatureBase = ComingSoon;

  activeFeature: boolean = false;
  activeComingSoon: boolean = false;

  constructor(
      public ref: DynamicDialogRef,
  ) { }


  ngOnInit(): void {

  }

  toggleMoreFeature(): void {
    this.activeFeature = !this.activeFeature;
  }

  toggleMoreComingSoon(): void {
    this.activeComingSoon = !this.activeComingSoon;
  }
}
