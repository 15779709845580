<div class="p-inputgroup prompt-wrapper">
<!--    <ng-container *ngIf="(selectedDropdownModel.name !== 'not-selected' || (selectedDropdownModel.name === 'not-selected' && !hidePrompt))">-->
    <ng-container *ngIf="IsPromptAvailable()">
        <textarea
            #input
            rows="1"
            pInputTextarea
            [autoResize]="true"
            [placeholder]="getPromptMessage()"
            [disabled]="chatTyping && chatTyping.isTyping"
            placeholder="Send a message"
            [(ngModel)]="promptMessage"
            (keydown.enter)="keydownHandler($event)"
            (keyup.enter)="sendPromptMessage()"
            (ngModelChange)="onChangeHandler($event)"
        >
        </textarea>
        <i
            *ngIf="promptMessage"
            class="clear-prompt pi pi-times cursor-pointer"
            [pTooltip]="'Clear input'" tooltipPosition="top" [showDelay]="1000"
            (click)="promptMessage = ''"
        ></i>
        <ng-container *ngIf="isGptTyping(); else sendButton">
            <button
                    pTooltip="Stop generating"
                    tooltipPosition="top"
                    type="button"
                    class="p-button-info"
                    pButton icon="pi pi-stop-circle"
                    (click)="stopTyping()"
            ></button>
        </ng-container>
        <ng-template #sendButton>
            <button
                    type="button"
                    class="p-button-info"
                    pButton icon="pi pi-send"
                    [disabled]="isSendButtonDisabled()"
                    (click)="sendPromptMessage()"
            ></button>
        </ng-template>
        <div class="version-info">
            <div><span class="corporate-color">Chat-iX</span> {{version}} by <a href="https://www.n-ix.com/opentext-consulting-services/" target="_blank" >N-iX OTPS</a> </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!IsPromptAvailable()">
        <ng-container *ngIf="selectedProject?.isLocked; else modelNotAvailable">
            <div class="not-available">This is an active Chat-iX Bot conversation in Workplace Chat.</div>
        </ng-container>
        <ng-template #modelNotAvailable>
            <div class="not-available"><span>The "{{ selectedModel.displayName }}" model is no longer available. Please <a class="select-model" (click)="openDropdownHandler()">select another model</a> to continue this conversation.</span></div>
        </ng-template>
    </ng-container>
</div>
