import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NewFavorite, NewProjectTypeName} from "../../../../interfaces/app.interfaces";
import {finalize, Subscription} from "rxjs";
import {SidebarSelection, SidebarSelectionService} from "../../../../services/sidebar-selection.service";
import {ConfirmationService, MenuItem} from "primeng/api";
import {ApiService, NotSelectedModel} from "../../../../services/api.service";
import {EventBusService, SpinnerLocation} from "../../../../services/event-bus.service";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {ImageCollectionService} from "../../../../services/image-collection.service";
import {Utils} from "../../../../utils/utils";
import {AppStateService} from "../../../../services/app.state.service";

@Component({
  selector: 'app-sidebar-favorite',
  templateUrl: './sidebar-favorite.component.html',
  styleUrls: ['./sidebar-favorite.component.scss'],
  providers:[]
})
export class SidebarFavoriteComponent implements OnInit, OnDestroy{
  @Input() project!: NewFavorite;

  newConversation!: Subscription;

  // State Subscriptions
  selectionSubscription!: Subscription;

  selectedProject!: SidebarSelection;

  // Constants
  readonly icons: {[index: string]: string} = {
    'gpt-4o': './assets/images/icons/small/gpt-logo-blue-small.svg',
    'gpt-4-turbo': './assets/images/icons/small/gpt-logo-blue-small.svg',
    'gpt-4-32k': './assets/images/icons/small/gpt-logo-blue-small.svg',
    'gpt-4': './assets/images/icons/small/gpt-logo-blue-small.svg',
    'gpt-35-turbo-16k': './assets/images/icons/small/gpt-logo-green-small.svg',
    'claude-35-sonnet': './assets/images/icons/small/claude-logo-small.svg',
    'claude-3-opus': './assets/images/icons/small/claude-logo-small.svg',
    'claude-3-sonnet': './assets/images/icons/small/claude-logo-small.svg',
    'claude-3-haiku': './assets/images/icons/small/claude-logo-small.svg',
    'gemini-15-pro': './assets/images/icons/small/gemini-logo-small.svg',
    'palm-2': './assets/images/icons/small/palm-logo-original-small.svg',
    'dall-e-3': './assets/images/icons/small/gpt-logo-purple-small.svg',
    'dall-e-2': './assets/images/icons/small/gpt-logo-purple-small.svg',
    'hr-assistant': './assets/images/icons/small/hr-assistant-logo-small.svg',
  }

  menuItems: MenuItem[] = [];

  constructor(
      private api: ApiService,
      private eventBus: EventBusService,
      private confirmationService: ConfirmationService,
      private selection: SidebarSelectionService,
      private imagesService: ImageCollectionService,
      private state: AppStateService,
  ) {
  }

  protected get isSelected() {
    if(this.selectedProject && this.project) {
      if(this.selectedProject.tempGuid === this.project.tempGuid){
        return true
      }
    }
    return false
  }

  subscribeToSelection(): Subscription {
    return this.selection.observe().subscribe((state) => {
      if(state && state.project) {
        this.selectedProject = state.project;

      }
    })
  }

  selectConversation() {
    this.selection.selectProject(this.project);
    // FOR MOBILE
    this.eventBus.openSidebar.next(false);
  }

  deleteConversation(projectId: string): void {
    this.api.deleteConversation(projectId).pipe(
        finalize(() => {
          this.eventBus.setIsLoading(false, SpinnerLocation.SideBar)
        })
    ).subscribe(() => {
      // this.eventBus.updateConversationsList.next(this.conversation);
      this.eventBus.deleteProject.send(this.project);
      this.eventBus.newChat.next(null);
    })
  }

  deleteImageCollection(projectId: string): void {
    this.imagesService.deleteImagesCollection(projectId).pipe(
        finalize(() => {
          this.eventBus.setIsLoading(false, SpinnerLocation.SideBar)
        })
    ).subscribe(() => {
      // this.eventBus.updateConversationsList.next(this.conversation);
      this.eventBus.deleteProject.send(this.project);
      this.eventBus.newChat.next(null);
    })
  }

  confirmDeleteProject(): void {
    this.confirmationService.confirm({
      key: Utils.getDeletionKey(this.project.projectTypeName),
      message: 'Are you sure you want to delete this conversation?',
      accept: () => {
        this.deleteProject();
      }
    });
  }

  deleteProject(): void {
    switch(this.project.projectTypeName) {
      case NewProjectTypeName.Conversation: {
        this.deleteConversation(this.project.id);
        return;
      }
      case NewProjectTypeName.ImageCollection: {
        this.deleteImageCollection(this.project.id);
        return;
      }
      default: {
        console.error('Error: Unknown project type');
      }
    }
  }

  doRename() {
    if(this.project) {
      this.eventBus.renameProject.send(this.project);
    }
  }

  toggleFavorites(status: boolean) {
    this.api.updateFavoriteStatus(this.project.id, status).subscribe((project) => {
      this.eventBus.toggleFavoriteProject.send({project, status});
    })
  }


  constructMenu() {
    this.menuItems.push({
      icon: 'pi pi-pencil',
      label: 'Rename',
      command: () => {
        this.doRename();
      }
    })
    if(this.project.isFavorite) {
      this.menuItems.push( {
        icon: 'pi pi-star-fill',
        label: 'Remove Favorite',
        command: () => {
          this.toggleFavorites(false);
        }
      })
    } else {
      this.menuItems.push( {
        icon: 'pi pi-star',
        label: 'Add to Favorites',
        command: () => {
          this.toggleFavorites(true);
        }
      })
    }
    this.menuItems.push({
      icon: 'pi pi-trash',
      label: 'Delete',
      disabled: this.project.isLocked,
      command: () => {
        this.confirmDeleteProject();
      }
    })
  }


  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.constructMenu();
    this.selectionSubscription = this.subscribeToSelection();
    this.newConversation = this.eventBus.newChat.subscribe(() => {
    });
  }


  protected readonly eval = eval;
}
