<div class="conversation-feature-outer">
    <div class="w-full">
<!--        <div class="conversation-toggle">-->
<!--            <p-selectButton-->
<!--                [options]="chatAIModels"-->
<!--                [(ngModel)]="selectedModel"-->
<!--                (onChange)="onModelChange($event)"-->
<!--                optionLabel="label"-->
<!--                optionValue="value">-->
<!--            </p-selectButton>-->
<!--        </div>-->
<!--        <div class="conversation-toggle mobile">-->
<!--            <p-selectButton-->
<!--                [options]="chatAIModelsMobile"-->
<!--                [(ngModel)]="selectedModel"-->
<!--                (onChange)="onModelChange($event)"-->
<!--                optionLabel="label"-->
<!--                optionValue="value">-->
<!--            </p-selectButton>-->
<!--        </div>-->
        <div class="conversation-toggle">
<!--            <div class="conversation-toggle__wrapper">-->
<!--                <p-dropdown-->
<!--                        optionLabel="label"-->
<!--                        optionValue="value"-->
<!--                        appendTo="body"-->
<!--                        [disabled]="true"-->
<!--                        [group]="true"-->
<!--                        [(ngModel)]="selectedModel"-->
<!--                        [options]="modelOptions"-->
<!--                        (onChange)="onModelChange($event)"-->
<!--                >-->
<!--                    <ng-template let-selectedModel pTemplate="selectedItem">-->
<!--                        <div class="flex align-items-center gap-2">-->
<!--                            <i [class]="selectedModel.icon"></i>-->
<!--                            <div>{{selectedModel.label}}</div>-->
<!--                        </div>-->
<!--                    </ng-template>-->
<!--                    <ng-template let-model pTemplate="item">-->
<!--                        <div class="flex align-items-center gap-2">-->
<!--                            <i [class]="model.icon"></i>-->
<!--                            <div>{{model.label}}</div>-->
<!--                        </div>-->
<!--                    </ng-template>-->
<!--                </p-dropdown>-->
<!--            </div>-->
        </div>
        <div class="feature-title">IT is AI</div>
    </div>
    <div *ngIf="feature" class="conversation-feature">
        <div class="feature-image-title">#NIXERS_AI_ART</div>
        <div class="flex align-items-center justify-content-between" style="min-height: 250px">
            <ng-container *ngFor="let image of randomImages;">
                <div class="img">
                    <app-conversation-feature-image
                        [image]="image"
                        style="display: inline-flex"
                    ></app-conversation-feature-image>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="feature.length === 0  && !comingSoon.title && !comingSoon.features">
            <span class="conversation-empty">Chat-iX</span>
        </ng-container>
    </div>
</div>
