export class Collection<T extends Object> {

    constructor(
        initialCollection: T[] = [],
        autoTriggerNewRef: boolean = false
    ) {
        this.localCollection = initialCollection;
        this.autoTriggerNewRef = autoTriggerNewRef;
    }

    protected localCollection: T[];

    protected autoTriggerNewRef: boolean;

    public append(item: T, bypassTriggerNewRef = false): Collection<T> {
        this.localCollection.push(item);
        if(!bypassTriggerNewRef) {
            this.performAutoTriggerNewRef();
        }
        return this;
    }

    performAutoTriggerNewRef() {
        if (this.autoTriggerNewRef) {
            this.newRef()
        }
    }

    public prepend(item: T, bypassTriggerNewRef = false): Collection<T> {
        this.localCollection.unshift(item);

        if(!bypassTriggerNewRef) {
            this.performAutoTriggerNewRef();
        }

        return this;
    }

    public delete(objectToDelete: T, keyField: keyof T, bypassTriggerNewRef = false): Collection<T> {
        this.localCollection = this.localCollection.filter((item: T) => {
            return objectToDelete[keyField] !== item[keyField]
        });

        if(!bypassTriggerNewRef) {
            this.performAutoTriggerNewRef();
        }

        return this;
    }

    public replaceItem(
        newItem: T,
        keyField: keyof T,
        additionalFieldsForMatchedItems: Partial<T> = {},
        additionalFieldsForNotMatchedItems: Partial<T> = {},
        bypassTriggerNewRef = false
    ): Collection<T> {
        this.localCollection = this.localCollection.map((item: T) => {
            if (newItem[keyField] === item[keyField]) {
                return {...newItem, ...additionalFieldsForMatchedItems};
            }
            return {...item, ...additionalFieldsForNotMatchedItems};
        }) as T[];
        if(!bypassTriggerNewRef) {
            this.performAutoTriggerNewRef();
        }

        return this;
    }

    public updateItem(
        item: T,
        keyField: keyof T,
        updateFields: Partial<T> = {},
        bypassTriggerNewRef = false
    ): Collection<T> {

        this.localCollection = this.localCollection.map((itemLocal: T) => {
            if(itemLocal[keyField] === item[keyField]) {
                return {...item, ...updateFields};
            }
            return itemLocal
        });

        if(!bypassTriggerNewRef) {
            this.performAutoTriggerNewRef();
        }

        return this;
    }

    public updateEachItem(
        updateFields: Partial<T> = {},
        bypassTriggerNewRef = false
    ): Collection<T> {

        this.localCollection = this.localCollection.map((item: T) => {
            return {...item, ...updateFields};
        });

        if(!bypassTriggerNewRef) {
            this.performAutoTriggerNewRef();
        }

        return this;
    }

    public insert(item: T, newIndex: number, bypassTriggerNewRef = false): Collection<T> {

        this.localCollection.splice(newIndex, 0, item);
        if(!bypassTriggerNewRef) {
            this.performAutoTriggerNewRef();
        }

        return this;
    }

    public filterOut(callback: FilterCallbackFn<T>, bypassTriggerNewRef = false) {

        this.localCollection = this.localCollection.filter(item => {
            return callback(item)
        });
        if(!bypassTriggerNewRef) {
            this.performAutoTriggerNewRef();
        }

        return this;
    }

    replaceCollection(collection: T[], bypassTriggerNewRef = false): Collection<T> {
        this.localCollection = collection;
        if(!bypassTriggerNewRef) {
            this.performAutoTriggerNewRef();
        }
        return this;
    }

    public newRef(): void {
        this.localCollection = [...this.localCollection];
    }

    public empty(bypassTriggerNewRef = false):Collection<T> {
        return this.replaceCollection([], bypassTriggerNewRef);
    }

    public get ref(): T[] {
        return this.localCollection;
    }
}


export type FilterCallbackFn<T> = (item:T) => boolean
