import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {
    private graphMeEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";

    constructor(private http: HttpClient) {}

    getUserProfilePicture(): Observable<Blob> {
        return this.http.get(this.graphMeEndpoint, {
            responseType: 'blob',
        });
    }
}
