<ng-container *ngIf="conversationItem.type !== ConversationItemType.None && conversationItem">
    <div class="conversation-item-wrapper flex align-items-end w-full mb-3">
        <ng-container *ngIf="conversationItem.type === ConversationItemType.Info">
            <p-divider align="center" type="solid" class="w-full">
                <span class="divider-text">{{conversationItem.message}}</span>
            </p-divider>
        </ng-container>
        <ng-container *ngIf="conversationItem.type !== ConversationItemType.Info">
            <ng-container *ngIf="isAnswer(conversationItem.type)">
                <div class="chat">
                    <img class="chat-logo" [src]="logoPath" alt="logo">
                    <ng-container *ngIf="conversationItem.model!.name === 'palm-2'">
                        <img class="chat-icon" [src]="palmLogoPath" alt="palm-logo">
                    </ng-container>
                    <ng-container *ngIf="conversationItem.model!.name.startsWith('gpt')">
                        <img class="chat-icon" [src]="gptLogoPath" alt="gpt-logo">
                    </ng-container>
                    <ng-container *ngIf="conversationItem.model!.name.startsWith('claude')">
                        <img class="chat-icon" [src]="claudeLogoPath" alt="claude-logo">
                    </ng-container>
                    <ng-container *ngIf="conversationItem.model!.name.startsWith('gemini')">
                        <img class="chat-icon" [src]="geminiLogoPath" alt="gemini-logo">
                    </ng-container>
                </div>
            </ng-container>
            <div
                    class="conversation-item"
                    [ngClass]="getItemClass(conversationItem)"
            >
                <div class="flex align-items-center justify-content-end">
                    <div class="use">
                        <button
                                pButton
                                type="button"
                                icon="pi pi-code"
                                pTooltip="Use message"
                                tooltipPosition="top"
                                [showDelay]="1000"
                                [disabled]="copyButtonDisabled"
                                (click)="useMessage(conversationItem.message)"
                                class="menu-btn p-button-text"
                        ></button>
                    </div>
                    <div class="copy">
                        <ng-container *ngIf="!isCopying">
                            <button
                                    pButton
                                    type="button"
                                    icon="pi pi-copy"
                                    pTooltip="Copy to clipboard"
                                    tooltipPosition="top"
                                    [showDelay]="1000"
                                    (click)="copyToClipBoard(conversationItem.message)"
                                    class="menu-btn p-button-text"
                            ></button>
                        </ng-container>
                        <ng-container *ngIf="isCopying">
                            <button
                                    pButton
                                    type="button"
                                    icon="pi pi-check"
                                    class="menu-btn p-button-text"
                            ></button>
                        </ng-container>
                    </div>
                </div>
                <ng-container *ngIf="isAnswer(conversationItem.type)">
                    <markdown
                            [className]="getClassForContent()"
                            clipboard
                            [data]="conversationItem.message"
                    >
                    </markdown>
                </ng-container>
                <ng-container *ngIf="!isAnswer(conversationItem.type)">
                    <pre>{{conversationItem.message}}</pre>
                </ng-container>
                <p-tag *ngIf="showUnsavedMessageWarning(conversationItem)" severity="danger" value="Message not saved. Copy before reloading or switching conversations to prevent loss."/>
                <span *ngIf="showWaitingDots" class="blinking-dots">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="40px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="84" cy="50" r="10" fill="#ff8200">
                            <animate attributeName="r" repeatCount="indefinite" dur="0.5952380952380952s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
                            <animate attributeName="fill" repeatCount="indefinite" dur="2.380952380952381s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#ff8200;#0095cb;#fa4616;#00a499;#ff8200" begin="0s"></animate>
                        </circle><circle cx="16" cy="50" r="10" fill="#ff8200">
                          <animate attributeName="r" repeatCount="indefinite" dur="2.380952380952381s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
                          <animate attributeName="cx" repeatCount="indefinite" dur="2.380952380952381s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
                        </circle><circle cx="50" cy="50" r="10" fill="#00a499">
                          <animate attributeName="r" repeatCount="indefinite" dur="2.380952380952381s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5952380952380952s"></animate>
                          <animate attributeName="cx" repeatCount="indefinite" dur="2.380952380952381s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5952380952380952s"></animate>
                        </circle><circle cx="84" cy="50" r="10" fill="#fa4616">
                          <animate attributeName="r" repeatCount="indefinite" dur="2.380952380952381s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.1904761904761905s"></animate>
                          <animate attributeName="cx" repeatCount="indefinite" dur="2.380952380952381s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.1904761904761905s"></animate>
                        </circle><circle cx="16" cy="50" r="10" fill="#0095cb">
                          <animate attributeName="r" repeatCount="indefinite" dur="2.380952380952381s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.7857142857142856s"></animate>
                          <animate attributeName="cx" repeatCount="indefinite" dur="2.380952380952381s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.7857142857142856s"></animate>
                        </circle>
                    </svg>
                </span>
            </div>
            <ng-container *ngIf="conversationItem.type === ConversationItemType.Question">
                <div class="avatar">
                    <nix-avatar
                            [label]="userInitials()"
                            [imageUrl]="profilePictureUrl()"
                            shape="circle">
                    </nix-avatar>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
