
<ng-container *ngIf="images && imageCollection">
    <div class="image-collection-wrapper">
        <div class="image-collection-inner">
            <ng-container *ngFor="let image of images; trackBy: trackByImageId">
                <div class="col-12 sm:col-6 md:col-4">
                    <div class="image-card-wrapper">
                        <div class="image-card">
                            <app-image
                                [image]="image"
                                [isFirst]="isFirstImage()"
                                [imageCollection]="imageCollection"
                                (onDelete)="handleDelete($event)"
                                (onCreate)="handleCreate($event)"
                            >
                            </app-image>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="images.length === 0">
                <div class="no-images">No images to display</div>
            </ng-container>
        </div>
    </div>
</ng-container>

