import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
// @ts-ignore
import {SSE as SSE} from "SSE.js" ;
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SseClient} from "ngx-sse-client";
import {of} from "rxjs";
import {Model} from "./api.service";
@Injectable({
  providedIn: 'root'
})
export class StreamService {

  constructor(
      private http: HttpClient,
      private sseClient: SseClient
  ) { }


  private readonly endpoint: string =  environment.backend.url;

  sendStreamingMessage(message: string, conversationId: string, model: Model) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'text/event-stream'
    });

    const data = {
      model: model.name,
      content: message
    }

    return this.sseClient.stream(`${this.endpoint}/streaming/conversations/${conversationId}/user-messages`,{keepAlive: false},{body: data}, 'POST');

  }

}
