<ng-container *ngIf="cachedImage && !failed">
    <p-card class="image-card">
        <div class="card-wrapper">
            <ng-container *ngIf="!isLoading">
                <div class="image" (click)="previewShown = true">
                    <div class="img" [style]="getBackgroundClass()"></div>
                    <span class="image-hover">
                        <i class="pi pi-eye" style="font-size: 2rem"></i>
                    </span>
                </div>
<!--                <div-->
<!--                    class="prompt"-->
<!--                    [pTooltip]="image.prompt"-->
<!--                    tooltipPosition="top"-->
<!--                    [showDelay]="1000"-->
<!--                >-->
                <div class="prompt">
                    <span class="prompt-text">{{image.prompt}}</span>
                    <div class="prompt-actions">
                        <div class="flex align-items-center justify-content-end">
                            <div class="use">
                                <button
                                    pButton
                                    type="button"
                                    icon="pi pi-code"
                                    pTooltip="Use message"
                                    tooltipPosition="top"
                                    [showDelay]="1000"
                                    (click)="usePrompt()"
                                    class="menu-btn p-button-text"
                                ></button>
                            </div>
                            <div class="copy">
                                <ng-container *ngIf="!isCopying">
                                    <button
                                        pButton
                                        type="button"
                                        icon="pi pi-copy"
                                        pTooltip="Copy to clipboard"
                                        tooltipPosition="top"
                                        [showDelay]="1000"
                                        (click)=copyPrompt()
                                        class="menu-btn p-button-text"
                                    ></button>
                                </ng-container>
                                <ng-container *ngIf="isCopying">
                                    <button
                                        pButton
                                        type="button"
                                        icon="pi pi-check"
                                        class="menu-btn p-button-text"
                                    ></button>
                                </ng-container>
                            </div>
                        </div>
                        <div>{{image.prompt}}</div>
                    </div>
                </div>
                <div class="image-footer">
                    <div class="image-model flex align-items-center justify-content-end gap-2">
<!--                        <i class="chat-icon {{image.model.name}}"></i>-->
<!--                        <span style="color: rgba(0, 0, 0, 0.6); font-size: 14px;">{{image.model.displayName}}</span>      -->
                        <i class="chat-icon {{imageModel?.name}}"></i>
                        <span style="color: rgba(0, 0, 0, 0.6); font-size: 14px;">{{imageModel?.displayName}}</span>
                    </div>
                    <button
                        pButton
                        type="button"
                        icon="pi pi-ellipsis-v"
                        (click)="menu.toggle($event)"
                        class="menu-btn p-button-danger p-button-text"
                    >
                    </button>
                </div>
                <p-menu #menu appendTo="body" [popup]="true" [model]="menuItems"></p-menu>
                <!--                <div class="actions">-->
<!--                    <button pButton pRipple type="button" *ngIf="!isCopying" icon="pi pi-copy" pTooltip="Copy prompt" tooltipPosition="top" [showDelay]="1000" (click)="copyPrompt()"  class="p-button-rounded p-button-warning p-button-outlined"></button>-->
<!--                    <button pButton pRipple type="button" *ngIf="isCopying" icon="pi pi-check" pTooltip="Copied to clipboard" tooltipPosition="top" [showDelay]="1000" class="p-button-rounded p-button-warning p-button-outlined"></button>-->
<!--                    <button pButton pRipple type="button" icon="pi pi-code" pTooltip="Use prompt" tooltipPosition="top" [showDelay]="1000" (click)="usePrompt()" class="p-button-rounded p-button-warning p-button-outlined"></button>-->
<!--                    <button pButton pRipple type="button" icon="pi pi-replay"  pTooltip="Regenerate" tooltipPosition="top" [showDelay]="1000" (click)="regenerateImage()" class="p-button-rounded p-button-warning p-button-outlined"></button>-->
<!--                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top" [showDelay]="1000" (click)="downloadImage()" class="p-button-rounded p-button-warning p-button-outlined"></button>-->
<!--                    <button pButton pRipple type="button" icon="pi pi-trash" pTooltip="Delete" tooltipPosition="top" [showDelay]="1000" (click)="confirmDeleteImage()" class="p-button-rounded p-button-warning p-button-outlined"></button>-->
<!--                </div>-->
            </ng-container>
            <div *ngIf="isLoading">
                <app-spinner [spinnerPosition]="SpinnerPosition.Absolute"></app-spinner>
            </div>
        </div>
    </p-card>
</ng-container>


<p-sidebar [(visible)]="previewShown" [fullScreen]="true" [baseZIndex]="10000" [closeOnEscape]="true">
    <div class="image-preview">
        <img [src]="generateUrl(image.imageUrl)" alt="previewImage">
    </div>
</p-sidebar>

<p-confirmDialog key="image-item" header="Delete Image"></p-confirmDialog>
