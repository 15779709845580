import moment from "moment";

export const Feature: IFeature[] = [
  {
    title: 'Version 1.65',
    date: moment('2024-07-16').toDate(),
    features: [
      {
        text: `New powerful Anthropic Claude 3.5 Sonnet model 🚀`
      },
      {
        text: `UI/UX improvements and bug fixes ✨`
      },
    ],
  },
  {
    title: 'Version 1.62',
    date: moment('2024-05-31').toDate(),
    features: [
      {
        text: `New OpenAI GPT-4o model`
      },
    ],
  },
  {
    title: 'Version 1.60',
    date: moment('2024-04-24').toDate(),
    features: [
      {
        text: `New Claude 3 models from Anthropic`
      },
      {
        text: `OpenAI DALL·E 3 text-to-image model`
      },
      {
        text: `UI/UX improvements`
      },
    ],
  },
  {
    title: 'Version 1.54',
    date: moment('2024-04-03').toDate(),
    features: [
      {
        text: `Minor fixes and improvements`
      },
    ],
  },
  {
    title: 'Version 1.52',
    date: moment('2024-02-23').toDate(),
    features: [
      {
        text: `New OpenAI models: "GPT 4.0 32k" and "GPT 4.0 Turbo"`
      },
      {
        text: 'Possibility to change model for existing conversations'
      },
      {
        text: 'Help'
      },
      {
        text: 'UI/UX improvements'
      },
    ],
  },
  {
    title: 'Version 1.48',
    date: moment('2023-09-14').toDate(),
    features: [
      {
        text: 'Image generation with OpenAI DALL·E'
      },
      {
        text: 'Image collections'
      },
      {
        text: 'Favorites'
      },
      {
        text: 'UI/UX improvements'
      },
    ],
  },
  {
    title: 'Version 1.12-beta',
    date: moment('2023-08-31').toDate(),
    features: [
      {
        text: 'Switched to the latest OpenAI GPT-3.5 model - version 0613'
      },
      {
        text: 'Enhanced text input area'
      },
      {
        text: 'Highlight syntax of modern programming languages'
      },
    ],
  },
  {
    title: 'Version 1.08-beta',
    date: moment('2023-08-23').toDate(),
    features: [
      {
        text: 'Google PaLM 2 support'
      },
      {
        text: 'Switching between AI models'
      },
    ],
  },
  {
    title: 'Version 1.01-beta',
    date: moment('2023-08-11').toDate(),
    features: [
      {
        text: 'Response streaming'
      },
      {
        text: 'Code snippets support'
      },
      {
        text: 'Auto-saving conversations'
      },
      {
        text: 'Preserve conversation context'
      },
    ],
  },
  {
    title: 'Version 0.01',
    date: moment('2023-08-07').toDate(),
    features: [
      {
        text: 'Initial version'
      },
    ],
  },
];

export const ComingSoon: IFeatureBase = {
  title: 'Coming soon',
  features: [
    {
      text: 'Shared conversations and image collections'
    },
    {
      text: 'Search functionality'
    },
    {
      text: 'Templates with predefined context'
    }
  ]
};

export interface IFeatureItem {
  text: string;
}

export interface IFeatureBase {
  title: string;
  features: IFeatureItem[];
}

export interface IFeature extends IFeatureBase {
  title: string;
  date: Date;
  features: IFeatureItem[];
}
