<ng-container *ngIf="image">
    <div class="image" (click)="previewShown = true" [pTooltip]="tooltipText()" tooltipPosition="top" [showDelay]="1000">
        <img [src]="image.thumbnailUrl" [alt]="image.prompt">
        <span class="image-hover">
            <i class="pi pi-eye" style="font-size: 2rem"></i>
        </span>
    </div>
</ng-container>

<p-sidebar [(visible)]="previewShown" [fullScreen]="true" [baseZIndex]="10000" [closeOnEscape]="true">
    <div class="image-preview">
        <img [src]="image.imageUrl" alt="previewImage">
    </div>
</p-sidebar>
