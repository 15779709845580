import {Observable, Subject, Subscription} from "rxjs";

export class EventBus<T> {

    constructor() {
        this.eventBus = new Subject<T>();
    }

    private eventBus: Subject<T>;

    public send(event: T) {
        this.eventBus.next(event);
    }

    public observe(): Observable<T> {
        return this.eventBus.asObservable();
    }

    public subscribe(callback: (event: T) => void): Subscription {
       return this.observe().subscribe(callback);
    }



}
