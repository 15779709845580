import { Component, OnInit } from '@angular/core';
import {EventBusService, SpinnerLocation} from "../../../services/event-bus.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent implements OnInit {


  constructor(
  ) { }

  ngOnInit(): void {

  }

}
