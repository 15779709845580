import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subscription} from "rxjs";
import {ApiService, Model, NotSelectedModel} from "../../../../../../../services/api.service";
import {AppStateService} from "../../../../../../../services/app.state.service";
import {EventBusService} from "../../../../../../../services/event-bus.service";
import {SidebarSelectionService} from "../../../../../../../services/sidebar-selection.service";
import {Dropdown} from "primeng/dropdown";

@Component({
  selector: 'app-conversation-toggle',
  templateUrl: './conversation-toggle.component.html',
  styleUrls: ['./conversation-toggle.component.scss']
})
export class ConversationToggleComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(Dropdown) modelDropdown!: Dropdown;

  @Input() isConversationSelected: boolean = false;
  @Input() isImageCollectionSelected: boolean = false;

  selectedProjectSubscription!: Subscription;
  selectedModelSubscription!: Subscription;
  openDropdownSubscription!: Subscription;
  newChatSubscription!: Subscription;
  selectedModel: string | null = null;

  modelNotSelectedPlaceholder: string = "";

  modelOptions: any = [];

  constructor(
      private state: AppStateService,
      private eventBus: EventBusService,
      private selection: SidebarSelectionService,
      private api: ApiService,
      private cd: ChangeDetectorRef,
  ) { }

  getDropdownValues(): void {
    const context = this.api.contextData;
    let availableModels = context?.availableModels;
    if (this.isConversationSelected) {
      availableModels = availableModels.filter(group => group.displayName === 'Chat');
    }
    if (this.isImageCollectionSelected) {
      availableModels = availableModels.filter(group => group.displayName === 'Images');
    }
    this.modelOptions = availableModels.map(group => ({
      label: group.displayName,
      value: group.displayName.toLowerCase(),
      items: group.models.map( (model) => ({
        icon: this.getIcon(model),
        label: model.displayName,
        value: model.name,
      }))
    }));
  }

  getIcon(model: Model): string {
    return `chat-icon ${model.name}`;
  }

  onModelChange(event: any): void {
    if (!this.isConversationSelected && !this.isImageCollectionSelected) {
      const selectedModel = this.api.getModelByName(event.value);
      this.state.selectedAIModel.next(selectedModel);
      // this.eventBus.newChat.next(null);
    } else {
      const selectedModel = this.api.getModelByName(event.value);
      this.state.selectedDropdownModel.next(selectedModel);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isConversationSelected = changes['isConversationSelected'];
    const isImageCollectionSelected = changes['isImageCollectionSelected'];
    if ((isConversationSelected && !isConversationSelected.firstChange) || (isImageCollectionSelected && !isImageCollectionSelected.firstChange)) {
        this.getDropdownValues();
    }
  }

  IsModelAvailable(modelName: string): boolean {
    return (this.api.contextData?.availableModels.flatMap(group => group.models).find(m => m.name === modelName) != null);
  }
  ngOnInit(): void {
    this.getDropdownValues();
    this.selectedModelSubscription = this.state.selectedAIModel.subscribe(value => {
      if (value) {
        this.selectedModel = value.name;
        this.cd.detectChanges();
      }
    });

    this.selectedProjectSubscription = this.selection.observe().subscribe((state) => {
      if (state?.project) {
        this.selectedModel = state.project.model.name;
        if (this.IsModelAvailable(state.project.model.name)) {
          this.selectedModel = state.project.model.name;
          this.modelDropdown.disabled = false;
          this.state.selectedDropdownModel.next(state.project.model);
          if (state.project.isLocked) {
            this.modelDropdown.disabled = true;
          }
        } else {
          this.modelDropdown.clear();
          this.state.selectedDropdownModel.next(new NotSelectedModel());
          if (state.project.isLocked) {
            this.modelNotSelectedPlaceholder = "Model not available";
            this.modelDropdown.disabled = true;
          } else {
            this.modelNotSelectedPlaceholder = "Model not selected";
            this.modelDropdown.disabled = false;
          }
        }
        this.cd.detectChanges();
      }
    });
    this.openDropdownSubscription = this.eventBus.openDropdown.subscribe((value) => {
      this.modelDropdown.show();
    })

    // fix bug that model dropdown remained disabled after starting new chat
    this.newChatSubscription = this.eventBus.newChat.subscribe(() => {
      this.modelDropdown.disabled = false;
    });
  }

  ngOnDestroy(): void {
    if (this.selectedModelSubscription) {
      this.selectedModelSubscription.unsubscribe();
    }
    if (this.selectedProjectSubscription) {
      this.selectedProjectSubscription.unsubscribe();
    }
    if (this.openDropdownSubscription) {
      this.openDropdownSubscription.unsubscribe();
    }
    if (this.newChatSubscription) {
      this.newChatSubscription.unsubscribe();
    }
  }

}