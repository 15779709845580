import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AppComponent} from "./app.component";
import {MainComponent} from "./main/main.component";
import {MsalGuard} from "@azure/msal-angular";
import {LoginFailedComponent} from "./login-failed/login-failed.component";


const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    canActivate: [MsalGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: "login-failed",
    component: LoginFailedComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
