import {Component, Input, OnInit} from '@angular/core';
import {IImage} from "../../../../../../sidebar/sidebar.component";

@Component({
  selector: 'app-conversation-feature-image',
  templateUrl: './conversation-feature-image.component.html',
  styleUrls: ['./conversation-feature-image.component.scss']
})
export class ConversationFeatureImageComponent implements OnInit {

  @Input() image!: IImage;

  previewShown = false;

  constructor() { }

  generateUrl(imageUrl: string):string {
    return imageUrl;
  }

  tooltipText(): string {
    return `${this.image.model.displayName}: ${this.image.prompt}`;
  }

  getBackgroundClass() {
    return `background-image: url(${this.generateUrl(this.image.thumbnailUrl)})`;
  }

  ngOnInit(): void {
  }

}
