import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-help',
  standalone: true,
  templateUrl: './help-content.component.html',
  styleUrls: ['./help-content.component.scss']
})

export class HelpContentComponent implements OnInit {

  constructor(
      public ref: DynamicDialogRef,
  ) { }


  ngOnInit(): void {

  }

}
