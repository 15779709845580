import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {Avatar, AvatarModule} from "primeng/avatar";
import {SafeUrl} from "@angular/platform-browser";
import {NgClass, NgIf, NgStyle} from "@angular/common";

// Custom Avatar component that supports blob: image URLs
// Attempts to display an image first and falls back to initials if the image/icons is not available
@Component({
  selector: 'nix-avatar',
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    AvatarModule,
    NgIf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  host: {
    class: 'nix-avatar'
  }
})
export class AvatarComponent extends Avatar {

  constructor() {
    super();
  }

  @Input() imageUrl: SafeUrl | undefined;
}
