import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SpinnerPosition} from "../../../../../shared/spinner/spinner.component";
import {EventBusService, SpinnerLocation} from "../../../../../services/event-bus.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-chat-main',
    templateUrl: './chat-main.component.html',
    styleUrls: ['./chat-main.component.scss']
})

export class ChatMainComponent implements OnInit {

    showSpinner: boolean = false;
    isLoadingSubscription!: Subscription;
    protected readonly SpinnerPosition = SpinnerPosition;

    constructor(
        private eventBus: EventBusService,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.isLoadingSubscription = this.eventBus.isLoading.subscribe(loadingData => {
            if (loadingData && (loadingData.location === SpinnerLocation.Body)) {
                this.showSpinner = loadingData.isLoading;
                this.cd.detectChanges();
            }
        })

    }
}
