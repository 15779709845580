import {computed, Injectable, Signal, signal} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
export abstract class StateModel<T> {

  private state = new BehaviorSubject<T>({} as T);
  constructor() {}

  public set(value: T): void {
    this.state.next(value);
  }

  public get(): T {
    return this.state.getValue();
  }

  public observe() : Observable<T> {
    return this.state.asObservable();
  }

}

