import {IEnvironment} from "./environment-model";

export const environment: IEnvironment = {
  production: false,
  msalConfig: {
    clientId: "ed9546d1-3f4a-4474-959c-4b31e378db3a",
    authority: "https://login.microsoftonline.com/b51ef730-8cd9-401d-9894-170fe94d0679/",
    redirectUri: "https://dev.chatix.n-ix.com",
    postLogoutRedirectUri: "https://dev.chatix.n-ix.com",
    scope: "api://ed9546d1-3f4a-4474-959c-4b31e378db3a/access"
  },
  backend: {
    url: "https://chatix-dev.azurewebsites.net/api",
    blobStorageUrl: "https://chatix.blob.core.windows.net"
  },
  dateFormat: {
    short: 'yyyy-MM-dd',
    long: 'yyyy-MM-dd HH:mm'
  }
};
