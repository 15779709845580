<div class="conversation-toggle__wrapper">
    <p-dropdown
            #modelDropdown
            optionLabel="label"
            optionValue="value"
            appendTo="body"
            [placeholder]=modelNotSelectedPlaceholder
            class="model-dropdown"
            [group]="true"
            [(ngModel)]="selectedModel"
            [options]="modelOptions"
            (onChange)="onModelChange($event)"
    >
        <ng-template let-selectedModel pTemplate="selectedItem">
            <div class="flex align-items-center gap-2">
                <i [class]="selectedModel?.icon"></i>
                <div>{{ selectedModel?.label }}</div>
            </div>
        </ng-template>
        <ng-template let-model pTemplate="item">
            <ng-container *ngIf="model; else placeholder">
                <div class="flex align-items-center gap-2">
                    <i [class]="model.icon"></i>
                    <div>{{ model.label }}</div>
                </div>
            </ng-container>
            <ng-template #placeholder>
                <div class="flex align-items-center gap-2">
                    <div>AAA</div>
                </div>
            </ng-template>
        </ng-template>
    </p-dropdown>
</div>
